
import Vue, { PropType } from 'vue';

import { getCategoryById } from '~/apollo-api/getCategoryById';
import { MakeSearchFilters_makeSearch_category, Category_category } from '~/apollo-api/types';

import BreadcrumbsDs from '~/components/breadcrumbs.vue';

const DEFAULT_BREADCRUMB_ITEMS = [{ text: 'Каталог', url: '#' }];

export default Vue.extend({
  components: {
    BreadcrumbsDs,
  },
  props: {
    category: {
      type: Object as PropType<MakeSearchFilters_makeSearch_category | null>,
      default: null,
    },
  },
  data() {
    return {
      data: null as Category_category | null,
    };
  },
  computed: {
    breadcrumbItems(): unknown[] {
      if (!this.data) {
        return DEFAULT_BREADCRUMB_ITEMS;
      }
      const items = JSON.parse(JSON.stringify(this.data.parentList));
      return [
        ...DEFAULT_BREADCRUMB_ITEMS,
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        ...items.map((category) => ({
          text: category.title,
          url: {
            path: `/catalog/${category.id}`,
          },
        })),
      ];
    },
  },
  watch: {
    category: {
      immediate: true,
      handler(value: MakeSearchFilters_makeSearch_category | null) {
        if (value && value.id) {
          this.onLoad(value.id);
        }
      },
    },
  },
  methods: {
    async onLoad(categoryId: number) {
      try {
        this.data = await getCategoryById(categoryId);
      } catch (error) {
        console.error(error);
      }
    },
  },
});
