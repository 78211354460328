<template lang="pug">
.ds-catalog-header
  breadcrumbs-ds(:items="breadcrumbItems")
  .content-header
    .dropdown-wrapper
      .dropdown(v-click-outside="hide" :class="{ 'show':isShowDropdown }")
        button-ui.title(@click.native="toggle" :class="{ 'no-children': !hasChildren}") {{ data.category.title || 'Каталог' }}
          icon-ui(:name="iconName" slot="after" v-if="hasChildren")
        .children(v-if="isShowDropdown && hasChildren")
          .col(v-for="(children,index) in separatedChildren" :key="`children${index}`")
            .child(
              v-for="item in children"
              :key="item.title"
              @click="onClick(item)"
            ) {{ item.title }}
    //-.description @todo implement
    //-  .text(v-html="textHtml")
    //-  .button(@click="()=>{this.isShowText=!this.isShowText;}") {{ isShowText ? 'Свернуть' : 'Раскрыть' }}
</template>

<script>
import IconUi from '~/components/common/icon';
import ButtonUi from '~/components/common/button';
import ClickOutside from 'vue-click-outside';
import BreadcrumbsDs from '~/components/breadcrumbs';

export default {
  components: { BreadcrumbsDs, IconUi, ButtonUi },
  directives: {
    ClickOutside,
  },
  inject: ['data', 'updateQuery'],
  data: () => ({
    isShowDropdown: false,
    isShowText: false,
    colCount: 2,
    description:
      'Независимо от того, ищете ли вы серьезные решения или ежедневный уход, у нас есть всё для ухоженного лица. Для насыщенного увлажнения, просмотрите наш выбор масел для лица — супер гидратирующих и роскошных, идеально дополняющих ваш режим ухода за кожей лица.\n' +
      '\n' +
      'Dry lips aren’t fun. Stay moisturised with our range of lip balms designed to soothe your pout. Want that fresh facial feeling at home? Scroll our offering of face masks for an instant dose of self-care. Give your everyday facial routine that extra boost with face serums and eye creams, all formulated to target your specific needs.\n',
  }),
  computed: {
    hasChildren() {
      return this.data.category?.children?.length;
    },
    breadcrumbItems() {
      const result = [{ text: 'Каталог', url: '#' }];
      if (!this.data.category.parentList) {
        return result;
      }
      result.push(...(this.data.category?.parentList || []).map((p) => this.toBreadCrumbItem(p)));
      return result;
    },
    separatedChildren() {
      let i, j;
      const result = [];
      const array = this.data.category.children;
      const chunk = Math.ceil(array.length / this.colCount);
      for (i = 0, j = array.length; i < j; i += chunk) {
        result.push(array.slice(i, i + chunk));
      }
      return result;
    },
    iconName() {
      return this.isShowDropdown ? 'navigation/chevron-up_line' : 'navigation/chevron-down_line';
    },
    textHtml() {
      const text = this.description; // this.data.category.description in feature
      return this.isShowText
        ? this.toHtml(text) // all paragraphs
        : text.split('\n')[0]; // first paragraph
    },
  },
  methods: {
    toBreadCrumbItem(category) {
      return { text: category?.title, url: { name: 'catalog', params: { dshopCategoryId: category?.id } } };
    },
    onClick(item) {
      this.updateQuery('dshopCategoryId', item.id);
    },
    isActive(item) {
      return +this.data.url.dshopCategoryId === +item.id;
    },
    toHtml(text) {
      return text.replace(/\n/g, '<br/>');
    },
    toggle() {
      if (this.hasChildren) {
        this.isShowDropdown = !this.isShowDropdown;
      }
    },
    hide() {
      this.isShowDropdown = false;
    },
  },
};
</script>
<style lang="stylus">
.ds-catalog-header
  .ds-breadcrumbs
    margin-top 8px
    margin-bottom 10px

  .title
    Text(CaptionM)

  .content-header
    display flex
    align-items flex-start
    align-content flex-start
    width 100%

    .dropdown-wrapper
      position relative
      margin-bottom 60px
      margin-left -32px
      width 50%

      .dropdown
        position absolute
        top 0
        margin 5px 32px 25px

        .title
          Button()
          padding 0
          margin-bottom 10px
          white-space nowrap
          Text(TitleL Medium Secondary)

          &.no-children
            cursor default

          svg
            margin-top 5px

        .children
          display flex

          .col
            .child
              Text(BodyM Link)
              white-space nowrap
              cursor pointer
              display block
              margin-top 15px
            +Media(Tablet, Laptop, Desktop, DesktopMax)
              &:last-child:not(:first-child)
                padding-left 50px
                padding-right 100px
          +Media(Mobile)
            flex-direction column

        &.show
          margin 0
          top -20px
          padding 25px 32px
          z-index 30
          background #fff
          box-shadow 0 2px 8px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.16)

  //-.description
  //-  Text(Caption Long)
  //-  width 50%
  //-  .button
  //-    cursor pointer
  //-    width min-content
  //-    font-weight 600
  //-    text-decoration underline
  //-    margin-top 10px
  +Media(Mobile, Tablet)
    margin-bottom 0
    .content-header
      flex-direction column

      .dropdown-wrapper
        width calc(100% + 50px)
        margin-left -29px
        margin-bottom 32px

        .dropdown
          margin 2px 28px 4px
          left 0
          right 0
          top -2px

          .title
            Text(TitleM Medium Short Secondary)
            margin 0

          &.show
            top -24px
            margin 0
            padding 24px 28px

//-  .description
//-    width 100%
</style>
