<template lang="pug">
.cabinet-order-page
  link-ui.to-orders(v-if="!isSuccessful" to="/cabinet/orders")
    icon-ui(name="navigation/chevron-left_line")
    span К списку заказов
  template(v-if="!$fetchState.pending && order")
    order-success-ds(v-if="isSuccessful" :order="order")
    order-ds(:order="order" :is-successful="isSuccessful" @update-order="loadOrder")
</template>

<script>
import OrderDs from './components/order';
import OrderSuccessDs from './components/order-success';
import { OrderItemStatus } from '~/apollo-api/types';
import IconUi from '~/components/common/icon';
import LinkUi from '~/components/common/link';
import { AuthenticatedMixin } from '~/mixins/authenticated';
import { getOrder } from '~/modules/orders/api';

export default {
  components: {
    OrderSuccessDs,
    IconUi,
    LinkUi,
    OrderDs,
  },
  mixins: [AuthenticatedMixin],
  provide() {
    return {
      isShowMapInit: true,
    };
  },
  beforeRouteEnter(to, _from, next) {
    if (to.params.id === 'order_id') {
      // @TODO: delete when backend fix redirect
      next({ name: 'cabinet-order', params: { id: to.query.successful }, query: { successful: '1' } });
    } else {
      next();
    }
  },
  props: {
    id: {
      type: String,
      default: '0',
    },
  },
  data: () => ({
    order: null,
    isSuccessful: false,
  }),
  async fetch() {
    try {
      await this.loadOrder();

      // Меняем статус из-за плавающего бага, при котором статус после оплаты не актуален
      if (this.isSuccessful) {
        this.order = {
          ...this.order,
          status: OrderItemStatus.RECEIVED,
        };
        if (this.order) {
          this.$gtm.push(this.$dataLayer.orderSuccessEvent(this.order));
        } else {
          const order = {
            id: this.id,
            price: 0,
            orderItems: [],
          };
          this.$gtm.push(this.$dataLayer.orderSuccessEvent(order));
        }
      }
    } catch (e) {
      const order = {
        id: this.id,
        price: 0,
        orderItems: [],
      };
      this.$gtm.push(this.$dataLayer.orderSuccessEvent(order));
      console.error(e);
    }
  },
  fetchDelay: 1000,
  fetchOnServer: false,
  created() {
    this.isSuccessful = Boolean(this.$route.query.successful);

    this.$router.replace({
      ...this.$route,
      query: {
        ...this.$route.query,
        successful: undefined,
      },
    });
  },
  methods: {
    async loadOrder() {
      try {
        this.order = await getOrder(Number(this.id));
      } catch (error) {
        console.error(error);
        this.$nuxt.error({ statusCode: 404, message: 'Такого заказа нет' });
      }
    },
  },
};
</script>

<style lang="stylus">
.cabinet-order-page
  .to-orders
    Button(Large)
    padding-left 0
    Text(BodyL Semibold)
</style>
