import { MakeSearchQueryInput, PaginationInput, ShowAdultContent } from '~/apollo-api/types';
import { DEFAULT_SORT_VALUE } from './sort-data';

export { SORT_DATA, DEFAULT_SORT_VALUE } from './sort-data';

export const DEFAULT_PAGINATION: PaginationInput = {
  offset: 0,
  limit: 60,
};

export const DEFAULT_QUERY_INPUT: MakeSearchQueryInput = {
  showAdultContent: ShowAdultContent.TRUE,
  filters: [],
  sort: DEFAULT_SORT_VALUE,
  pagination: DEFAULT_PAGINATION,
};

export const DEFAULT_FILTERS_PAGINATION: PaginationInput = {
  offset: 0,
  limit: 0,
};
