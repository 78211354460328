<template lang="pug">
.checkout-address-street
  dropdown-ds(
    ref="dropdown"
    :list="streets"
    value-attribute="name"
    label-attribute="name"
    :value="value"
    @select="onSelect"
    @focus.native.capture="onTrigger(true)"
    @blur.native.capture="onTrigger(false)"
  )
    template(v-slot:option="props")
      div.option
        div.option-string(v-html="toBold(props.option)")
        span.option-substring {{ props.option.substring }}
    labeled-input(
      :key="keyInput"
      label="Адрес получателя:"
      :is-error="!(value && value.name) && !!error.length"
      :value="value ? value.name : filteredText"
      placeholder="Введите адрес до номера дома"
      autocomplete="street-address"
      @input="onInput"
      @keypress.enter="onEnter"
    )
      icon-ui.clear-btn(
        v-if="(filteredText || value) && isActive"
        name="operations/close_line"
        :size="20"
        @mousedown.native.prevent.stop="clear"
        slot="after"
      )
      icon-ui.clear-btn(
        v-if="!isActive"
        name="operations/chevron-down_small-line"
        slot="after"
      )
      icon-ui.clear-btn(
        v-if="!(filteredText || value) && isActive"
        name="operations/chevron-up_small-line"
        slot="after"
        @mousedown.native.prevent.stop="blurDropdown"
      )
  .error(v-if="errorPrompt && errorPrompt.length && checkStreetName && checkStreetName.length > 1") {{ errorPrompt }}
    span.prompt(@click="selectLast()") {{` ${checkStreetName}` }}
  .error(v-else-if="error && error.length") {{ error }}
</template>
<script>
import { mapState } from 'vuex';

import IconUi from '~/components/common/icon';
import InputUi from '~/components/common/input';
import DropdownDs from '~/components/dropdown';
import LabeledInput from '~/components/labeled-input';
import { AddressSearchMixin } from '~/modules/dadata-actions/mixins/address-search';
import { toBold } from '~/utils/intersections';
export default {
  components: {
    LabeledInput,
    InputUi,
    DropdownDs,
    IconUi,
  },
  mixins: [AddressSearchMixin],
  props: {
    street: {
      type: Object,
      default: () => { },
    },
    error: {
      type: String,
      default: () => '',
    },
    errorPrompt: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    isActive: false,
    keyInput: 0,
    currentCity: null,
    showError: false,
  }),
  computed: {
    value() {
      return { name: this.street.street, house: this.street.house };
    },
    ...mapState('checkout', {
      checkStreetName: (state) =>
        state.checkStreets && state.checkStreets.length > 0 ? state.checkStreets[0].name : null,
    }),
    ...mapState('checkout', ['city']),
  },
  watch: {
    async filteredText(value) {
      if (value === '') {
        this.updateCity();
      }

      await this.updateAddress();
    },
    isActive(value) {
      setTimeout(() => {
        if (!value && this.currentCity.name != this.filteredText && this.streets[0].name != this.filteredText) {
          this.updateCity({ name: this.filteredText, city: this.filteredText });
        } else if (!value && this.streets && this.streets[0] && this.streets[0].name === this.filteredText) {
          this.updateCity(this.streets[0]);
        }
      }, 300);
    },
    city() {
      this.showError = false;
    },
  },
  mounted() {
    if (this.$refs.dropdown) {
      this.$refs.dropdown.select(this.value);
    }
  },
  methods: {
    toBold(option) {
      return toBold(this.filteredText, option.name);
    },
    clear() {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.select(undefined);
      }

      this.filteredText = '';
      this.blurDropdown();
    },
    blurDropdown() {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.blur();
      }
    },
    onInput(value) {
      this.filteredText = value;
    },
    onSelect(option) {
      if (option) {
        this.filteredText = option.name;
        this.currentCity = option;
        this.blurDropdown();
      }

      this.updateCity(option);
    },
    onEnter() {
      if (this.streets.length > 0 && this.$refs.dropdown) {
        this.$refs.dropdown.select(this.streets[0]);
      }
    },
    selectLast() {
      this.updateCity(this.checkStreets[0]);
    },
    updateCity(payload) {
      this.$emit(
        'select',
        payload?.name || '',
        payload?.house || '',
        payload?.street_with_type || '',
        payload?.house_type || '',
        payload?.settlement_with_type || '',
      );
      this.$nextTick(() => {
        // force update
        this.keyInput++;
      });
    },
    onTrigger(active) {
      this.isActive = active;
    },
  },
};
</script>
<style lang="stylus">
.checkout-address-street
  .ds-dropdown
    display flex
    width 100%
    align-items center
    .drop-target
    .ds-labeled
      width 100%
    .ui-input
      Input(Medium Outlined)
      display flex
      width 100%
    .clear-btn
      position relative
      right 28px
      margin-right -24px
      cursor pointer
      &:hover
        path
          fill Gray(D32)
    .option
      &-string
        color: #27282A
        font-size: 14px
        line-height: 120%
      &-substring
        color: #62656A
        font-size: 11px
        line-height: 125%
  .error
    Text(CaptionM)
    color Red()
    margin-top 8px
    .prompt
      color: #002CF0
      cursor pointer
</style>
