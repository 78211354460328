export const basicToken =
  process.env.NUXT_ENV_GQL_TOKEN ||
  (process.env.NODE_ENV !== 'development' ? 'cHNiX3Nob3A6cHNiX3Nob3Bfc2VjcmV0' : 'cHNiX3Nob3A6ZGVkX3Nob3Bfc2VjcmV0');
export const daDataToken = 'da64de1422eccdca35d8598dc52dd6340964dd59';

export const PROD_URL = 'https://dshop.xn--80abntiqkep.xn--p1ai/';
export const DEV_URL = 'https://graphql-dshop.dev.xn----8sbbsyksmer.xn--p1ai';

export const LOUIS_API_HOST = 'https://louis.dev.cluster.kznexpess.com';
export const LOUIS_KEY =
  'ZG7rqGpD1t2XFamS7mhK4Wi1maDQAD6Y0wi7wDHg6PUNGDnQnSs657MWompaTfdkFRxwDiASjcvfuV3CVP0Sv4n3bBHcJuavTxqBBonukFeBktFOzfA0ivao70znfbKZeup9NtI3MSsTeVJLjEi6EYW15PSXmKF3szGi409REXvNlIxRzwYTCT76j17mb2wocvfZLEKn';

export const MINDBOX_ENDPOINT_ID =
  process.env.NUXT_ENV_MB_ENDPOINT ||
  (process.env.NODE_ENV !== 'development' ? 'Psbmarket.Website' : 'Psbmarket.Website.test');

export const CLICKSTREAM_URL =
  process.env.NUXT_ENV_CS_URL ||
  (process.env.NODE_ENV !== 'development'
    ? 'https://el.xn--80abntiqkep.xn--p1ai/events' // https://el.псбмаркет.рф
    : 'https://clickstream-psb.dev.cluster.kznexpess.com/events');

export const SENTRY_DSN = 'https://709ae77794d83e30adc5a701108e4cd5@sentry.xn----8sbbsyksmer.xn--p1ai/3';
export const SENTRY_ENVIRONMENT = process.env.NODE_ENV !== 'development' ? 'development' : 'production';

export const MAX_WEIGHT_RUSSIAN_POST = 19000; // in grams

export const dshopName = 'psb-dshop';
export const HOST = 'https://xn--80abntiqkep.xn--p1ai'; // 'https://псбмаркет.рф'
export const columnSeparator = '<meta class="divider"></meta>';

const preconnectHrefs = [
  'https://ke-images.servicecdn.ru',
  'https://fonts.gstatic.com',
  'https://fonts.googleapis.com',
  PROD_URL,
];

export const preconnectLinks = [
  ...preconnectHrefs.map((href) => ({
    rel: 'preconnect',
    href,
  })),
  ...preconnectHrefs.map((href) => ({
    rel: 'dns-prefetch',
    href,
  })),
];

export const preloadLinks = [
  'https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2',
  'https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2',
  'https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2',
  'https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73oTd4jQmfxI.woff2',
  'https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73oDd4jQmfxIC7w.woff2',
].map((href) => ({
  rel: 'preload',
  href,
  as: 'font',
  type: 'font/woff2',
  crossorigin: true,
}));

export const titles = {
  default: 'ПСБ Маркет — надежный интернет-магазин экосистемы банка ПСБ',
};

export const descriptions = {
  default:
    'Повседневные товары, электроника и тысячи других товаров по низким ценам, оплатой ПСБ-баллами и выгодными рассрочками',
};

export const staticData = {
  links: {
    privacy: 'https://legal.kazanexpress.ru/privacy-policy.html',
    socials: [
      {
        link: 'https://t.me/psb_market',
        icon: 'social/telegram',
      },
      {
        link: 'https://vk.com/psbmarket',
        icon: 'social/vk',
      },
      {
        link: 'https://t.me/psbmarket_official_bot?start=restart-ynsi0J8g_footer',
        icon: 'social/support',
      },
    ],
  },
  support: {
    email: '',
    prEmail: '',
    partnersEmail: '',
    phone: '88007009616',
    phoneFormat: '# ### ### ## ##',
    chat: 'https://t.me/PSBMarket_Bot',
    nameChat: '@PSBMarket_Bot',
    time: {
      from: '9:00',
      to: '21:00',
    },
    holidayTime: {
      from: '10:00',
      to: '22:00',
    },
  },
  DescCatalogList: [],
  customCities: [],
  contacts: {
    inn: 9722025064,
    kpp: 772201001,
    ogrn: 1227700406955,
  },
  brandShort: 'ПСБ Маркет',
  ownDpName: 'ПСБ Маркет',
  brandFull: 'Общество с ограниченной ответственностью "ПСБ МАРКЕТ"',
  brand: 'ООО "ПСБ МАРКЕТ"',
  yearRights: new Date().getFullYear(),
  faq: {
    menu: [
      {
        title: 'Как сделать заказ?',
        link: '/faq#how-make-order',
        id: 'how-make-order',
        html: `<p class="slightly transparent font-14">Заказ оформляется в несколько шагов. Ничего сложного.<br><br>Для
        того, чтобы купить на сайте ПСБ Маркет:</p>
      <ul>
        <li class="slightly transparent font-14">Зарегистрируйтесь;</li>
        <li class="slightly transparent font-14">Нажмите кнопку «Добавить в корзину» при просмотре интересующих Вас товаров;
        </li>
        <li class="slightly transparent font-14">Перейдите в раздел «Корзина», и нажмите кнопку «Перейти к оформлению»;
        </li>
        <li class="slightly transparent font-14">Выберите свой населенный пункт на странице оформления заказа, после чего Вам будут доступны следующие способы доставки: Почтой России

        </li>
        <li class="slightly transparent font-14">Доставка Почтой России: введите Ваш точный адрес и почтовый индекс, после чего на основе введенных данных будет произведен расчет стоимости доставки;

        </li>
        <li class="slightly transparent font-14">Доставка до пункта выдачи: выберите в списке или на карте удобную точку выдачи в Вашем городе;

        </li>
        <li class="slightly transparent font-14">Доставка курьером: введите ваш точный адрес, выберите дату и временной промежуток времени, в который вам будет удобно получить свой заказ;
        </li>
        <li class="slightly transparent font-14">Укажите номер телефона, e-mail, фамилию и имя получателя после заполнения информации о способе доставки;
        </li>
        <li class="slightly transparent font-14">Проверьте введенные вами данные и нажмите на кнопку "Подтвердить и оплатить";
        </li>
        <li class="slightly transparent font-14">Если Вы не авторизовались на данном этапе, система попросит Вас авторизоваться или зарегистрироваться, после чего переведет на страницу оплаты;
        </li>
        <li
            class="slightly transparent font-14">Выберите удобный для Вас способ оплаты, введите платежные реквизиты, нажмите кнопку «Оплатить», пройдите этап подтверждения оплаты через SMS;
        </li>
        <li class="slightly transparent font-14">На новой странице Вы увидите подтверждение проведения оплаты. Нажмите кнопку «Продолжить покупки», чтобы вернуть в магазин, или кнопку «К списку заказов», чтобы увидеть статус Ваших покупок.
        </li>
      </ul>`,
      },
      {
        title: 'Как оплатить?',
        html: `<p class="slightly transparent font-14">Оплата товара происходит по полной предоплате онлайн. Оператор приема платежей - ПАО "ПРОМСВЯЗЬБАНК".
        <br>Принимаем к оплате банковские карты, у которых 16, 18 или 19 цифр в номере (как дебетовые, так и кредитные банковские карты):</p>
      <ul>
        <li class="slightly transparent font-14">VISA, MasterCard, American Express;</li>
        <li class="slightly transparent font-14">VISA Electron/Plus, Cirrus/Maestro при наличии кода CVC2 и
          CVV2
          (в верхнем правом углу полосы для подписи);
        </li>
        <li class="slightly transparent font-14">МИР.</li>
      </ul>
      <p class="slightly transparent font-14">Инструкция по оплате:</p>
      <ul>
        <li class="slightly transparent font-14">Нажмите кнопку "Подтвердить и оплатить" на странице оформления заказа;</li>
        <li class="slightly transparent font-14">Введите номер карты, срок действия и код CVC2/CVV2
        </li>
        <li class="slightly transparent font-14">Нажмите "Оплатить"</li>
        <li class="slightly transparent font-14">Дождитесь SMS с кодом подтверждения операции и введите его в платежной форме.
        </li>
        <li class="slightly transparent font-14">Если оплата прошла успешно, то обновленная страница бразуера уведомит вас об этом.
        </li>
      </ul>`,
        id: 'how-pay',
      },
      {
        id: 'get-method',
        html: `<p class="slightly transparent font-14">В зависимости от выбранного вами населенного пункта доставки у вас будет на выбор будет до трех
        способов получения заказа:</p>
        <ul>
            <li class="slightly transparent font-14">Забрать в отделении Почты России.</li>
        </ul>
   `,
        title: 'Способы получения',
      },
      //   {
      //     id: 'return-product',
      //     title: 'Как вернуть товар?',
      //     html: `
      //   <p class="slightly transparent font-14">Раздел по способу возврата товаров, заказанных с помощью Почты России будет дополнен позже.</p>`,
      //   },
      //   {
      //     id: 'return-money',
      //     title: 'Возврат денег',
      //     html: `<p class="slightly transparent font-14">Как только возврат товаров будет принят администратором пункта выдачи или курьером, мы запросим у банка возврат средств. Деньги возвращаются на карту, с которой была произведена оплата. Чаще всего средства возвращаются в течение трех рабочих дней, но максимальные сроки зачисления средств на банковскую карту зависят от платежных систем (MasterCard, VISA, МИР), а также от Вашего банка и могут составлять до 30 дней.
      // </p>`,
      //   },
      {
        id: 'order-location',
        title: 'Где мой заказ?',
        html: `<p class="slightly transparent font-14">Узнать где заказ вы можете в вашем личном кабинете в разделе Мои
        заказы. <br><br> Что означает статус?
      </p>
      <ul class="numb">
        <li class="slightly transparent font-14">Ожидает оплату - оплатите заказ, и мы подготовим его к
          доставке.
        </li>
        <li
            class="slightly transparent font-14">В обработке – мы трудимся над упаковкой и доставкой заказа.
        </li>
        <li class="slightly transparent font-14">Готово к выдаче – Ваш заказ ожидает в пункте выдачи.
        </li>
        <li class="slightly transparent font-14">Выдано / Отменено / Возврат – это конечные статусы заказа, означающие его получение, отмену или возврат.

        </li>
      </ul>`,
      },
    ],
  },
  subheader:
    '<div>Покупайте с бесплатной доставкой за 1 день. <nuxt-link to="/faq#get-method">Условия доставки</nuxt-link></div>',
  description: '',
  seo: `<strong style="font-weight: 500">ПСБ Маркет — надежный интернет-магазин экосистемы Банка ПСБ.</strong>

  <p>У нас Вы найдете повседневные товары, электронику и тысячи других товаров по низким ценам, с оплатой ПСБ-баллами и выгодными рассрочками.
  </p><meta class="divider"></meta>
  <p>ПСБ — универсальный банк, основанный в 1995 году. Входит в десятку крупнейших банков России и в список системно значимых кредитных организаций, утвержденный Центробанком. В конце декабря 2019 года ПСБ был законодательно присвоен статус опорного банка для оборонно-промышленного комплекса (ОПК). Услугами банка пользуются 2,5 миллиона физических и свыше 200 тысяч юридических лиц, в том числе более 10 тысяч крупных корпоративных клиентов. Сеть банка насчитывает около 300 точек продаж в России.</p>
  `,
  profits: [
    {
      icon: 'navigation/loyalty-program_line',
      subtitle: 'Экономия 90%, оплачивая бонусными баллами ПСБ',
    },
    {
      icon: 'status/pickpoint_line',
      subtitle: 'Доставка по всей РФ (удобная и быстрая)',
    },
    {
      icon: 'status/quality_line',
      subtitle: 'Рассрочки и льготное кредитование от банка ПСБ',
    },
    {
      icon: 'status/secure-payment_line',
      subtitle: 'Дополнительные скидки с картой банка ПСБ',
    },
  ],
  checkoutFias: [],
  isShowKazanExpressTechnologies: false,
  isShowDiscountProductBadge: true,
  isApplyOldSearch: false,
  isShowSEOText: false,
  isShowProfits: false,
  isShowBadges: false,
  isShowReviews: false,
  isShowOrderComment: true,
};

export const SEO_DATA_PAGES = {
  SEARCH: 'SEARCH',
  PRODUCT: 'PRODUCT',
  OFFER: 'OFFER',
  CATALOG: 'CATALOG',
  ALL: 'ALL',
};

const buildSeoData = ({ title, meta }, path) => ({
  title,
  meta: Object.entries(meta).map(([name, content]) => ({
    hid: name,
    name,
    content,
  })),
  link: [
    {
      rel: 'canonical',
      href: `${HOST}${path}`,
    },
  ],
});

const addLink = (path, links) =>
  links
    ? {
        link: [
          {
            rel: 'canonical',
            href: `${HOST}${path}`,
          },
          ...links,
        ],
      }
    : {
        link: [
          {
            rel: 'canonical',
            href: `${HOST}${path}`,
          },
        ],
      };

export const seoData = {
  [SEO_DATA_PAGES.SEARCH]: (path) => {
    const title = 'ПСБ Маркет — интернет-магазин от Промсвязьбанка';
    const description =
      'Повседневные товары, электроника и тысячи других товаров по выгодным ценам от одного из крупнейших банков России';

    return buildSeoData(
      {
        title,
        meta: {
          description,
          keywords:
            'маркетплейс, интернет-магазин, каталог товаров, низкие цены, выгодные цены, ПСБ, ПСБ маркет, Промсвязьбанк',
          'og:title': title,
          'og:description': description,
          'og:image': `${HOST}/images/logo.png`,
          'twitter:title': title,
          'twitter:description': description,
          'twitter:image': `${HOST}/images/logo.png`,
        },
      },
      path,
    );
  },
  [SEO_DATA_PAGES.PRODUCT]: (value = {}, path) =>
    buildSeoData(
      {
        title: `${value.title} за ${value.price} ₽ купить в интернет-магазине ПСБ Маркет от Промсвязьбанка`,
        meta: {
          description: `Купить ${value.title}  в интернет-магазине от одного из крупнейших банков России: отзывы, описание, фото товара, стоимость`,
          keywords: `${value.title}, купить, маркетплейс, скидки, интернет-магазин, каталог товаров, низкие цены, ПСБ, ПСБ маркет, Промсвязьбанк`,
        },
      },
      path,
    ),
  [SEO_DATA_PAGES.OFFER]: (value = {}, path) =>
    buildSeoData(
      {
        title: `${value.title}: товары в интернет-магазине от Промсвязьбанка`,
        meta: {
          description:
            'Эксклюзивные предложения, а также тысячи других товаров по выгодным ценам от одного из крупнейших банков России',
          keywords:
            'эксклюзивы, маркетплейс, интернет-магазин, каталог товаров, низкие цены, ПСБ, банк ПСБ, ПСБ Маркет, Промсвязьбанк',
        },
      },
      path,
    ),
  [SEO_DATA_PAGES.CATALOG]: (value = {}, path) => {
    const title = value.title || 'Каталог';
    const description =
      'Повседневные товары, электроника и тысячи других предложений по выгодным ценам от одного из крупнейших банков России';

    return buildSeoData(
      {
        title: value.title ? `Купить ${title} в интернет-магазине от Промсвязьбанка` : title,
        meta: {
          description,
          keywords: `${title}, маркетплейс, интернет-магазин, каталог товаров, низкие цены, выгодные цены, ПСБ, ПСБ Маркет, Промсвязьбанк`,
          'og:title': title,
          'og:description': description,
          'og:image': `${HOST}/images/logo.png`,
          'twitter:title': title,
          'twitter:description': description,
          'twitter:image': `${HOST}/images/logo.png`,
        },
      },
      path,
    );
  },
  [SEO_DATA_PAGES.ALL]: (path) => {
    return addLink(path);
  },
};
