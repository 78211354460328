export const flattenCategory = (categories) => {
  let result = [];
  categories.forEach((category) => {
    const newCategory = { ...category };

    if (category.children) {
      delete newCategory.children;
      result.push(newCategory);
      result = result.concat(flattenCategory(category.children));
    } else {
      result.push(newCategory);
    }
  });
  return result;
};
