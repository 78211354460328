import { cloneDeep } from '@apollo/client/utilities';

import { client } from './index';

export async function cachedQuery({ query, variables, parser }) {
  let data;
  const cachedData = client.readQuery({ query, variables });

  // Read cached data (if any)
  if (cachedData) {
    data = cloneDeep(cachedData);
  }
  // Fetch data and store in the cache
  else {
    data = cloneDeep((await client.query({ query, variables })).data);
    client.writeQuery({ query, data, variables });
  }

  // Return parsed data
  return parser(data);
}
