import Cookies from 'js-cookie';
import cookie from 'cookie';
export default function ({ store, redirect, route, req }) {
  const tokens = Cookies.get('auth');
  let isAuth = false;
  if (process.server) {
    isAuth = cookie.parse(req.headers.cookie).auth
      ? JSON.parse(cookie.parse(req.headers.cookie).auth).accessToken
      : false;
  } else {
    isAuth = tokens ? JSON.parse(tokens).accessToken : false;
  }

  if (!isAuth) {
    return redirect({ name: 'signin', query: { path: route.path } });
  }
}
