import { setContext } from '@apollo/client/link/context';
// d-shop basic tokens
import { basicToken } from '~/shop-config';
import Cookies from 'js-cookie';
import { v4 as guidGenerator } from 'uuid';

export const getBearerToken = () => {
  const storage = Cookies.get('auth');
  if (!storage) {
    return null;
  }
  return JSON.parse(storage).accessToken;
};

export const IID_LOCAL_STORAGE_KEY = 'iid';
export const getInstallId = () => {
  // if (process.server) {
  //   return null;
  // }
  let iidFromLocalStorage = Cookies.get(IID_LOCAL_STORAGE_KEY);

  if (!iidFromLocalStorage) {
    iidFromLocalStorage = guidGenerator() as string;
    Cookies.set(IID_LOCAL_STORAGE_KEY, iidFromLocalStorage, {
      expires: 14,
      secure: process.env.NODE_ENV !== 'development',
    });
  }

  return iidFromLocalStorage;
};

// authLink – just need it to work with tokens in headers
export const authLink = setContext((_, { headers }) => {
  const bearerToken = getBearerToken();
  return {
    headers: {
      ...headers,
      authorization:
        _.operationName === 'refreshToken'
          ? `Basic ${basicToken}` // if query is refreshToken then we have expired access token
          : bearerToken
          ? `Bearer ${bearerToken}`
          : `Basic ${basicToken}`,
      'X-IID': getInstallId(),
    },
  };
});
