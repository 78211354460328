<template lang="pug">
.about-page
  .ui-wrapper
    .sidebar
      menu-vertical(
        :menu="menu"
        :current-menu-item="currentMenuItem"
      )
    .content
      .title-menu(v-if="!media.isMobileOrTablet") {{ currentMenuItem.title }}
      nuxt-child
</template>

<script>
import MenuVertical from '~/components/menu-vertical';
import { SeoDataMixin } from '~/mixins/seoData';

export default {
  name: 'About',
  components: {
    MenuVertical,
  },
  mixins: [SeoDataMixin],
  inject: ['media'],
  head() {
    return this.seoData[this.seoDataPages.ALL](this.$route.path);
  },
  computed: {
    currentMenuItem() {
      return this.menu.find((_) => _.link.name === this.$route.name);
    },
    menu() {
      return [
        {
          link: { name: 'about-company' },
          title: 'О компании',
        },
        {
          link: { name: 'about-contacts' },
          title: 'Контакты',
        },
      ];
    },
  },
};
</script>

<style lang="stylus">
.about-page
  margin-top 25px

  .ui-wrapper
    display flex
    +Media(Tablet, Mobile)
      flex-direction column

  .sidebar
    .ds-menu-vertical
      +Media(Laptop, Desktop, DesktopMax)
        position sticky
        top 20px
    +Media(DesktopMax)
      min-width 290px
      margin-right 60px
    +Media(Desktop, Laptop)
      min-width 250px
      margin-right 60px

  .content
    flex-grow 1
    .title-menu
      Text(HeadlineS)
</style>
