<template lang="pug">
.ds-subheader.ds-component
  .ui-wrapper
    .first-links
      link-ui.content(:to="{ name: 'delivery-points' }")
        icon-ui(name="operations/pickup" :size="18" style="margin-right: 4px")
        span Пункты выдачи
      link-ui.content(:to="{ name: 'about-company' }") О компании
    link-ui.content(:to="static.support.chat") Служба поддержки
</template>
<script>
import LinkUi from '~/components/common/link';
import { StaticDataMixin } from '~/mixins/staticData';
import IconUi from '~/components/common/icon.vue';
export default {
  components: {
    LinkUi,
    IconUi,
  },
  mixins: [StaticDataMixin],
};
</script>
<style lang="stylus">
.ds-subheader
  height 34px
  background-color Gray(L50)
  display: flex
  align-items center
  .ui-wrapper
    display: flex
    justify-content: space-between
    align-items center
    .first-links
      display: flex
      gap 24px
      align-items center
  .content
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    display: flex
    align-items: center
    line-height: 18px;
    text-align: center;
    color #8B8E99
</style>
