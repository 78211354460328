<template>
  <div class="termsofuse-page" style="text-align: left">
    <div class="profile-container">
      <div class="termsofuse-page__inner">
        <div v-if="text && text.privacy" class="ql-editor" v-html="text.privacy"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { dshopTexts } from '~/apollo-api/dshopTexts';
import { SeoDataMixin } from '~/mixins/seoData';

export default {
  mixins: [SeoDataMixin],
  data() {
    return {
      text: null,
    };
  },

  head() {
    return this.seoData[this.seoDataPages.ALL](this.$route.path);
  },

  mounted() {
    this.dshopTexts();
  },
  methods: {
    async dshopTexts() {
      this.text = await dshopTexts('privacy');
    },
  },
};
</script>

<style lang="stylus">
.termsofuse-page
  width 95%
  margin 0 auto

  &__inner
    display flex
    flex-direction column
    border: 1px solid rgba(0,0,0,.12);
    padding: 32px;
    gap 24px
    +Media(Mobile, Tablet)
      padding 16px
      gap 12px

  &__title
    line-height 140%
    text-transform uppercase
    font-weight 600
    text-align center
</style>
