
import Vue, { PropType } from 'vue';
import searchResetFilters from '~/modules/search/components/filters/search-reset-filters.vue';
import {
  MakeSearchFilters_makeSearch_category,
  MakeSearchFilters_makeSearch_categoryTree,
  MakeSearchFilters_makeSearch_facets,
  MakeSearchQueryInput,
} from '~/apollo-api/types';

import SearchSortDs from '~/modules/search/components/filters/search-sort.vue';
import SearchFiltersDs from '~/modules/search/components/filters/search-filters.vue';

export default Vue.extend({
  components: {
    SearchFiltersDs,
    SearchSortDs,
    searchResetFilters,
  },
  inject: ['media'],
  props: {
    hasCategoryFilter: {
      type: Boolean,
      default: false,
    },
    facets: {
      type: Array as PropType<MakeSearchFilters_makeSearch_facets[] | null>,
      default: null,
    },
    category: {
      type: Object as PropType<MakeSearchFilters_makeSearch_category | null>,
      default: null,
    },
    categoryTree: {
      type: Array as PropType<MakeSearchFilters_makeSearch_categoryTree[]>,
    },
    queryInput: {
      type: Object as PropType<MakeSearchQueryInput>,
      required: true,
    },
  },

  computed: {
    hasFilters() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.$route.query.filters ? !!this.$route.query.filters.length : false;
    },
  },

  mounted() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.observeOptions();
  },

  methods: {
    observeOptions() {
      const observer = new IntersectionObserver(([e]) => e.target.classList.toggle('sticky', e.intersectionRatio < 1), {
        threshold: [1],
        rootMargin: '0px 100px 0px 100px',
      });
      observer.observe(this.$el as HTMLElement);
    },
  },
});
