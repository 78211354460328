<template lang="pug">
.app(:class="`page-${$route.name}`")
  .header
    header-ds
      logo
  .header-search(v-if="showSearch" id="header-search-block")
    button-ui.button-return(:class='{"visible": isArrow}' @click='closeMobileSuggestions')
      icon-ui.search-return-icon(name='navigation/back_sharp' size='28')
    search-suggestions(@close-menu="isMenuOpen = false")
  .page-content
    nuxt
  footer-ds(v-if="isCabinet")
  popups-container-ds
</template>

<script>
import Common from './common';
import SignInPopupMixin from '~/mixins/signInPopupMixin';
import HeaderDs from '~/components/header';
import LinkUi from '~/components/common/link';
import Logo from '~/components/common/logo';
import { PRODUCTS_SIZE } from '~/modules/offer/data';
import SubheaderDs from '~/components/subheader';
import FooterDs from '~/components/footer';
import PopupsContainerDs from '~/modules/popups/popups-container';
import { PopupsService } from '~/modules/popups/services/popups-service';
import { PopupsContainerModel } from '~/modules/popups/models';
import { staticData } from '~/shop-config';
import { SearchSuggestions } from '~/modules/search-suggestions';
import ButtonUi from '~/components/common/button.vue';
import IconUi from '~/components/common/icon.vue';

export default Common.extend({
  name: 'DefaultLayout',
  components: {
    PopupsContainerDs,
    SubheaderDs,
    HeaderDs,
    FooterDs,
    LinkUi,
    Logo,
    SearchSuggestions,
    ButtonUi,
    IconUi,
  },
  mixins: [SignInPopupMixin],
  data() {
    return {
      isShowDeliveryBanner: staticData.isShowDeliveryBanner,
      isArrow: true,
    };
  },
  computed: {
    isShowSearch() {
      return this.$route.name !== 'signup';
    },
    showSearch() {
      return (
        this.$route.name == 'home' ||
        this.$route.name == 'search' ||
        this.$route.name == 'catalog' ||
        this.$route.name == 'offer' ||
        this.$route.name == 'delivery-points' ||
        (this.$route.name == 'mobile-catalog' &&
          !JSON.parse(this.$route.query.isDeep ? this.$route.query.isDeep : 'false'))
      );
    },
  },
  watch: {
    size: {
      handler(value) {
        if (value) {
          this.getMainData(PRODUCTS_SIZE[value], this.isMobileOrTablet);
        }
      },
      immediate: true,
    },
    $route: {
      handler() {
        if (this.$route.name === 'home' || this.$route.name === 'mobile-catalog') {
          this.isArrow = false;
        } else {
          this.isArrow = true;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$eventManager.on('open-signin-popup', this.openSignInPopup);
    this.$eventManager.on('entry-end', this.closePopup);
    this.$eventManager.on('registration-end', this.closePopup);
    if (process.client) {
      this.getMainCategories();
    }
  },
  beforeDestroy() {
    this.$eventManager.off('open-signin-popup', this.openSignInPopup);
    this.$eventManager.off('entry-end', this.closePopup);
    this.$eventManager.off('registration-end', this.closePopup);
  },
  methods: {
    closePopup() {
      if (this.$route.name !== 'signin') {
        PopupsService.close(PopupsContainerModel.ETypeWrapper.CENTER);
      }
    },
    onLogoClick() {
      const name = 'home';

      if (this.$route.name === name) {
        return;
      }
      this.$router.push({ name });
    },
    closeMobileSuggestions() {
      if (this.$route.name === 'catalog') {
        return this.$router.push({ path: '/mobile-catalog' });
      }
      return this.$router.go(-1);
    },
  },
});
</script>

<style lang="stylus">
.no-scroll-menu
  +Media(Mobile, Tablet)
    overflow hidden
</style>

<style lang="stylus" scoped>
.app
  position relative
  display flex
  flex-direction column
  min-height 100vh
  padding-bottom: 44px
  .page-content
    flex-grow 1
    display: flex
    flex-direction: column

  &.page-product
  &.page-cart
    .ds-footer
      +Media(Mobile, Tablet)
        display none
.header-search
  width 100%
  padding 0 16px
  position sticky
  display flex
  top: 0
  border-bottom: 0.3px solid #D0D2D9;
  z-index 20
  background-color: #fff

svg.search-return-icon >>> path
  fill BlueBrand()
.without-search
  +Media(Mobile, Tablet)
    display none
.show-product
  display flex
  align-items center
  justify-content center

.button-return
  width 24px
  height 24px
  margin-right 8px
  display none

.visible
  display flex
</style>
