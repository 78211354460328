<template lang="pug">
.ds-catalog-page
  search-ds( :additional-query-input='additionalQueryInput' search-type="catalog")
</template>

<script>
import CatalogHeaderDs from './components/catalog-header.vue';
import { getCategoryById } from '~/apollo-api/getCategoryById';

import { SeoDataMixin } from '~/mixins/seoData';
import { StaticDataMixin } from '~/mixins/staticData';

import SearchDs from '~/modules/search/components/index.vue';

import { isEqualArray } from '~/modules/common/equal-array';

export default {
  components: { SearchDs, CatalogHeaderDs },

  mixins: [SeoDataMixin, StaticDataMixin],

  props: {
    dshopCategoryId: {
      type: [String, Number],
      default: undefined,
    },
  },

  async asyncData({ params, redirect, error }) {
    if (params.dshopCategoryId) {
      let category = undefined;
      await getCategoryById(Number(params.dshopCategoryId))
        .then((data) => {
          category = data;
          if (!category) {
            redirect('not-found');
          }
        })
        .catch((err) => {
          error('Категория не найдена');
        });
      if (category) {
        return {
          category,
        };
      } else {
        return {
          category: undefined,
        };
      }
    }
    return {
      category: undefined,
    };
  },

  head() {
    return this.seoData[this.seoDataPages.CATALOG](this.category, this.$route.path);
  },

  computed: {
    additionalQueryInput() {
      return {
        categoryId: this.dshopCategoryId,
      };
    },

    query() {
      return this.$route.query.query || '';
    },
    sort() {
      return +this.$route.query.sort || 0;
    },
    price() {
      return this.$route.query.price || [undefined, undefined];
    },
    dshopFilterCategories() {
      const result = this.$route.query.cat || [];
      return Array.isArray(result) ? result : [result];
    },
    url() {
      return {
        query: this.query,
        dshopCategoryId: this.dshopCategoryId,
        dshopFilterCategories: this.dshopFilterCategories,
        sort: this.sort,
        price: this.price,
      };
    },
  },

  beforeDestroy() {
    this.$eventManager.off('updateQuery', this.onUpdateQuery);
  },

  mounted() {
    this.$eventManager.on('updateQuery', this.onUpdateQuery);
  },

  methods: {
    onUpdateQuery({ name, value }) {
      if (name === 'price' && this.$route.query.price) {
        if (!!this.$route.query.price[0] && +this.$route.query.price[0] < +this.$route.query.price[1]) {
          value[0] = this.$route.query.price[0];
        }
      }
      const query = { ...this.$route.query };
      const params = {
        ...this.$route.params,
        toPosition: { y: Math.min(window.scrollY, this.$refs.searchResult.$refs.content.offsetTop) },
      };
      name = name === 'dshopFilterCategories' ? 'cat' : name;
      if (name === 'dshopCategoryId') {
        params[name] = value;
        this.$router.replace({ name: 'catalog', params, query: { ...query, cat: undefined } }); // filter categories delete
        return;
      }
      if (isEqualArray(this.$route.query[name], value)) {
        // does not update same value
        return;
      }
      if (value === undefined) {
        delete query[name];
      } else {
        query[name] = value;
      }
      this.$router.replace({ query, params });
    },
  },
};
</script>
