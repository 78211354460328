<template lang="pug">
nuxt-img.smooth(
  v-bind="$attrs"
  v-on="$listeners"
  @load="onLoad"
  loading="lazy"
  :class="{ 'loaded': isLoad }"
)
</template>
<script>
export default {
  data: () => ({
    isLoad: false,
  }),
  methods: {
    onLoad() {
      this.isLoad = true;
    },
  },
};
</script>
<style lang="stylus">
img.smooth
  transition opacity .1s ease-out
  opacity 0
  &.loaded
    opacity 1
</style>
