<template lang="pug">
.select-city
  .header(@click='changeTab')
    icon-ui(name='navigation/chevron-left_line' :size='24' fill='#2C2D84')
    span Вернуться к списку
  .title-menu(v-if='media.isMobileOrTablet') Населенный пункт
  .label(v-if='!media.isMobileOrTablet') Населенный пункт
  .ds-dropdown-city
    dropdown-ds(
      ref='dropdown'
      :list='concatCities'
      value-attribute='name'
      label-attribute='name'
      :value='value'
      show-list
      @select='onSelect'
      @focus.native.capture='isActive = true'
      @blur.native.capture='isActive = false'
    )
      template(v-slot:option='props')
        .option(v-html='toBold(props.option)')
      input-ui(
        :key='keyInput'
        ref='input'
        :value='filteredText'
        placeholder='Введите населенный пункт'
        @keypress.enter='onEnter'
        autocomplete='home city'
        v-bind='$attrs'
        @keyup="onInput"
      )
        icon-ui.clear-btn(v-if='!isActive' name='navigation/search_sharp' fill='#C2C4CC' slot='after')
        template(v-else-if='value && value.name ? true : filteredText.length > 0 ? true : false')
          icon-ui.clear-btn(
            name='operations/close_line'
            :size='20'
            slot='after'
            @mousedown.native.prevent.stop='clear'
          )
</template>
<script>
import IconUi from '~/components/common/icon';
import InputUi from '~/components/common/input';
import DropdownDs from '~/components/dropdown';
import LabeledInput from '~/components/labeled-input';
import { CitySearchMixin } from '~/modules/dadata-actions/mixins/city-search';
import { toBold } from '~/utils/intersections';
import { mapState } from 'vuex';
import { CITY_WITH_PARTNER_DP } from '~/utils/no-ke';
import { StaticDataMixin } from '~/mixins/staticData';

const TOP_CITIES = [
  'Луганская Народная респ, г Луганск',
  'Донецкая Народная респ, г Донецк',
  'Донецкая Народная респ, г Мариуполь',
  'Луганская Народная респ, г Алчевск',
  'Донецкая Народная респ, г Харцызск',
  'Луганская Народная респ, г Краснодон',
  'Каменск-Шахтинский',
].map((name) => ({
  name,
  city: name,
  id: name,
}));

// const TOP_CITIES = [
//   'Луганская Народная респ, г Луганск',
//   'Донецкая Народная респ, г Донецк',
//   'Донецкая Народная респ, г Мариуполь',
//   'Луганская Народная респ, г Алчевск',
//   'Донецкая Народная респ, г Харцызск',
//   'Каменск-Шахтинский',
// ]

export default {
  components: {
    LabeledInput,
    InputUi,
    DropdownDs,
    IconUi,
  },
  mixins: [CitySearchMixin, StaticDataMixin],
  inject: ['media'],
  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    filteredText: '',
    currentCity: null,
    isActive: false,
    keyInput: 0,
    partnerCity: CITY_WITH_PARTNER_DP,
    topCities: TOP_CITIES,
  }),
  computed: {
    /**
     * @property keCities - get KE cities
     * @property checkCities - get cities from dadata suggestion
     * @property concatCities - get cities for dropdown list in case of input
     * @property keCheckCities - get cities for check list before upload dadata suggestions
     */
    ...mapState('deliveryMap', ['fiasCities']),
    checkCities() {
      return this.$store.state.checkout.checkCities;
    },
    concatCities() {
      const customCityList = this.static.customCities.map((name) => ({
        name,
        city: name,
        id: name,
      }));
      // const localCitiesList = this.localCities.map((name) => ({
      //   name,
      //   city: name,
      //   id: name,
      // }));

      return [
        ...this.fiasCities.map((topCity) => {
          if (customCityList.find((city) => city.name == topCity.name)) {
            topCity.isCustom = true;
          }
          return topCity;
        }),
      ].filter((c) => !!this.value || c.name.toLowerCase().includes(this.filteredText.toLowerCase()));
    },

    isValid() {
      return !!this.value;
    },
  },
  watch: {
    isActive(active) {
      /**
       * Check value if blur
       * Hack, allow the onselect event to pass before the blur event
       */
      setTimeout(() => {
        if (
          !active &&
          this.currentCity &&
          this.currentCity.name !== this.filteredText &&
          this.allCities.length > 0 &&
          this.concatCities[0].name != this.filteredText
        ) {
          this.updateCity({ name: this.filteredText, id: this.filteredText, city: this.filteredText });
        } else if (!active && this.concatCities[0].name === this.filteredText) {
          this.updateCity(this.concatCities[0]);
        }
      }, 300);
    },
  },

  mounted() {
    if (this.$refs.dropdown) {
      this.$refs.dropdown.select(this.value);
    }
    if (this.value) {
      this.filteredText = JSON.parse(JSON.stringify(this.value.name));
    }
  },
  methods: {
    toBold(option) {
      return toBold(this.filteredText, option.name);
    },
    clear() {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.select({ name: '', id: '', city: '' });
      }
      this.focusInput();
      this.filteredText = '';
    },
    blurDropdown() {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.blur();
      }
    },
    focusInput() {
      // HACK: Need some delay before setting a focus to input.
      // This is caused by icon being clicked but still keeping a focus.
      // Probably, we have to refactor the way how icon behaves and remove a delay later.
      setTimeout(() => this.$refs.input.focus(), 100);
    },
    onInput(e) {
      this.filteredText = e.target.value;
    },

    async onSelect(option) {
      if (option) {
        this.filteredText = option.name;
        this.currentCity = option;
        this.updateCity(option);
      }
      this.blurDropdown();
      this.$store.dispatch('checkout/updateHiddenPoint', undefined);
      this.$store.dispatch('checkout/updateComment', '');
    },
    onEnter() {
      if (this.concatCities.length > 0 && this.$refs.dropdown) {
        this.$refs.dropdown.select(this.concatCities[0]);
      }
    },
    updateCity(payload) {
      if (
        this.localCities.find((city) => {
          return (
            (payload.name && payload.name.includes(city)) ||
            (payload.city && payload.city.includes('Каменск-Шахтинский'))
          );
        })
      ) {
        this.$store.commit('deliveryMap/updateDeliveryMapCity', payload);
        this.$store.commit('deliveryMap/updateStoredCity', payload);
        this.$emit('change-tab', 0);
      }

      this.$nextTick(() => {
        // force update
        this.keyInput++;
      });
    },
    changeTab() {
      this.$emit('change-tab', 0);
    },
  },
};
</script>
<style lang="stylus">
.select-city {
  padding: 0px 24px 0px 24px;

  +Media(Mobile, Tablet) {
    padding: 0 16px;
  }

  .header {
    color: #2C2D84;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-left: -8px;
    cursor: pointer;
  }

  .label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 8px;
  }

  .ds-dropdown-city {
    width: 100%;
    display: flex;
    align-items: center;
    width: 392px;

    +Media(Mobile, Tablet) {
      width: 100%;
    }

    +Media(Laptop) {
      width: 392px;
    }

    .ds-dropdown {
      width: 100%;

      .ui-input {
        display: flex;
        width: 100%;
        height: 48px;
        Input(Large Outlined);
      }

      .clear-btn {
        position: relative;
        right: 28px;
        margin-right: -24px;
        cursor: pointer;

        &:hover {
          path {
            fill: Gray(D32);

            .label {
              Text(BodyM);
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}
</style>
