<template lang="pug">
component(
  :is="asyncComponent" @updateFavorite="getFavorite"
)
</template>

<script>
import { FeedbackSortType } from '~/apollo-api/types';
import { ESkuGroupType } from '~/modules/search/models';
import Layoutable from '~/plugins/layoutable/mixin';
import { getProductPage } from '~/apollo-api/getProductPage';
import { HOST, staticData } from '~/shop-config';
import { getSkuGroupsByIdProduct } from '~/apollo-api/favorite';

export default {
  mixins: [Layoutable(['mobile', 'desktop'])],
  provide() {
    return {
      product: this.product,
      feedbacks: this.feedbacks,
    };
  },
  async asyncData({ params, query, error, redirect }) {
    const productId = Number(params.id);

    const characteristicIds = query[ESkuGroupType.CharacteristicId];
    const characteristicValueIds = query[ESkuGroupType.CharacteristicValueId];

    let skuGroup = {};
    if (characteristicIds && characteristicValueIds) {
      skuGroup = {
        characteristicIds: Array.isArray(characteristicIds)
          ? characteristicIds.map(Number)
          : [Number(characteristicIds)],
        characteristicValueIds: Array.isArray(characteristicValueIds)
          ? characteristicValueIds.map(Number)
          : [Number(characteristicValueIds)],
      };
    }
    let productPage = null;
    await getProductPage({
      productPageId: productId,
      sort: FeedbackSortType.RELEVANCE,
      page: 0,
      size: 10,
    })
      .then((data) => {
        productPage = data;
        if (!productPage) {
          redirect('not-found');
        }
      })
      .catch((err) => {
        error('Продукт не найден');
      });
    if (productPage) {
      return {
        product: {
          ...productPage.product,
          skuGroup,
        },
        feedbacks: staticData.isShowReviews ? productPage.feedbacks : [],
      };
    } else {
      return {
        product: null,
        feedbacks: null,
      };
    }
  },
  head() {
    return {
      meta: [
        { hid: 'og:url', property: 'og:url', content: `${HOST}${this.$route.path}` },
        { hid: 'og:title', property: 'og:title', content: this.product.title },
        { hid: 'og:description', property: 'og:description', content: this.product.shortDescription },
        { hid: 'og:image', property: 'og:image', content: this.product.photos?.[0].original.high },

        // Twitter
        { hid: 'twitter:title', name: 'twitter:title', content: this.product.title },
        { hid: 'twitter:description', name: 'twitter:description', content: this.product.shortDescription },
        { hid: 'twitter:image', name: 'twitter:image', content: this.product.photos?.[0].original.high },
      ],
    };
  },
  created() {
    if (process.client) {
      this.$gtm.push(this.$dataLayer.productOpenEvent(this.product));
    }
  },
  methods: {
    async import(l) {
      return await import('./' + l);
    },
    async getFavorite() {
      const data = await getSkuGroupsByIdProduct(Number(this.$route.params.id));
      this.product.skuGroups = data.skuGroups;
    },
  },
};
</script>
