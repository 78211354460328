export default (context) => {
  window.popStateDetected = false;
  window.addEventListener('popstate', () => {
    window.popStateDetected = true;
  });

  const search_pages = ['search', 'catalog', 'offer'];
  context.app.router.afterEach((to, from) => {
    const IsItBackButton = window.popStateDetected;
    window.popStateDetected = false;
    if (
      !(search_pages.some((_) => _ === from.name) && to.name === 'product') &&
      !(IsItBackButton && from.name === 'product' && search_pages.some((_) => _ === to.name))
    ) {
      context.store.dispatch('cache/clearSearchCards');
      context.store.dispatch('cache/clearSearchQuery');
    }
  });
};
