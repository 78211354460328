<script>
const defaultIconSize = 24;
const className = 'ui-icon';

export default {
  name: 'IconUi',

  inheritAttrs: false,

  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: [String, Number],
      default: defaultIconSize,
    },
    width: {
      type: [String, Number],
      default: 0,
    },
    height: {
      type: [String, Number],
      default: 0,
    },
  },

  data() {
    return {
      template: '',
      tagName: 'svg', // as 'svg' | 'img'
    };
  },

  watch: {
    name: {
      handler(val) {
        val && this.loadTemplate(val);
      },
      immediate: true,
    },
  },

  methods: {
    async loadTemplate(name) {
      if (name.match(/^(f|ht)tps?:\/\//i)) {
        this.tagName = 'img';

        return;
      } else {
        this.tagName = 'svg';
      }
      try {
        this.template = await import(
          /* webpackChunkName: "[request]" */
          /* webpackMode: "lazy" */
          /* webpackPrefetch: true */
          `!raw-loader!ui-icons/${name}.svg`
        ).then((_) => _.default);
      } catch (error) {
        console.error(`icon ${name} not found.`, error);
      }
    },
  },

  render(h) {
    let options = {};

    if (!this.template && this.tagName === 'svg') {
      return h('');
    }

    const divElement = document.createElement('div');

    if (this.tagName === 'svg') {
      divElement.innerHTML = this.template;
      const svgElement = divElement.firstChild;
      const attrs = {
        ...(svgElement && {
          ...Array.from(svgElement.attributes).reduce((acc, el) => {
            acc[el.name] = el.value;

            return acc;
          }, {}),
        }),

        width: this.width || this.size,
        height: this.height || this.size,

        ...this.$attrs,
      };

      options = {
        attrs,
        staticClass: [className, svgElement.classList.value].join(' '),
        domProps: {
          innerHTML: svgElement.innerHTML,
        },
      };
    } else {
      options = {
        attrs: {
          width: this.width || this.size,
          height: this.height || this.size,
          src: this.name,

          ...this.$attrs,
        },
        staticClass: className,
      };
    }

    return h(this.tagName, options);
  },
};
</script>
