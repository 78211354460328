/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCart
// ====================================================

export interface getCart_cart_sku_photo_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface getCart_cart_sku_photo {
  __typename: 'Photo';
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: getCart_cart_sku_photo_link;
}

export interface getCart_cart_sku_characteristicValues_characteristic {
  __typename: 'ProductCharacteristic';
  /**
   * Заголовок характеристики
   */
  title: string;
}

export interface getCart_cart_sku_characteristicValues {
  __typename: 'CharacteristicValue';
  /**
   * Заголовок значения характеристики
   */
  title: string;
  /**
   * Для хар-ки цвет - RGB значение
   */
  value: string;
  /**
   * Характеристика
   */
  characteristic: getCart_cart_sku_characteristicValues_characteristic;
}

export interface getCart_cart_sku_product {
  __typename: 'Product';
  /**
   * ID продукта
   */
  id: number;
  /**
   * Название продукта
   */
  title: string;
  /**
   * Краткое описание продукта
   */
  shortDescription: string | null;
}

export interface getCart_cart_sku {
  __typename: 'Sku';
  /**
   * Доступное количество по SKU
   */
  availableAmount: number;
  /**
   * ID SKU
   */
  id: number;
  /**
   * Стоимость SKU без скидки (зачеркнутая цена)
   */
  fullPrice: number;
  /**
   * Цена продажи СКУ
   */
  sellPrice: number;
  /**
   * SKU title, не используется на клиентах
   */
  skuTitle: string;
  /**
   * Основная фотография для СКУ
   */
  photo: getCart_cart_sku_photo;
  /**
   * Значения характеристик SKU
   */
  characteristicValues: getCart_cart_sku_characteristicValues[];
  /**
   * Продукт, к которому относится SKU
   */
  product: getCart_cart_sku_product;
  /**
   * Вес SKU
   */
  weight: number | null;
}

export interface getCart_cart {
  __typename: 'CartItem';
  amount: number;
  sku: getCart_cart_sku;
}

export interface getCart {
  cart: getCart_cart[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setCartItems
// ====================================================

export interface setCartItems_setCartItems_sku_photo_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface setCartItems_setCartItems_sku_photo {
  __typename: 'Photo';
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: setCartItems_setCartItems_sku_photo_link;
}

export interface setCartItems_setCartItems_sku_characteristicValues_characteristic {
  __typename: 'ProductCharacteristic';
  /**
   * Заголовок характеристики
   */
  title: string;
}

export interface setCartItems_setCartItems_sku_characteristicValues {
  __typename: 'CharacteristicValue';
  /**
   * Заголовок значения характеристики
   */
  title: string;
  /**
   * Для хар-ки цвет - RGB значение
   */
  value: string;
  /**
   * Характеристика
   */
  characteristic: setCartItems_setCartItems_sku_characteristicValues_characteristic;
}

export interface setCartItems_setCartItems_sku_product {
  __typename: 'Product';
  /**
   * ID продукта
   */
  id: number;
  /**
   * Название продукта
   */
  title: string;
  /**
   * Краткое описание продукта
   */
  shortDescription: string | null;
}

export interface setCartItems_setCartItems_sku {
  __typename: 'Sku';
  /**
   * Доступное количество по SKU
   */
  availableAmount: number;
  /**
   * ID SKU
   */
  id: number;
  /**
   * Стоимость SKU без скидки (зачеркнутая цена)
   */
  fullPrice: number;
  /**
   * Цена продажи СКУ
   */
  sellPrice: number;
  /**
   * SKU title, не используется на клиентах
   */
  skuTitle: string;
  /**
   * Основная фотография для СКУ
   */
  photo: setCartItems_setCartItems_sku_photo;
  /**
   * Значения характеристик SKU
   */
  characteristicValues: setCartItems_setCartItems_sku_characteristicValues[];
  /**
   * Продукт, к которому относится SKU
   */
  product: setCartItems_setCartItems_sku_product;
  /**
   * Вес SKU
   */
  weight: number | null;
}

export interface setCartItems_setCartItems {
  __typename: 'CartItem';
  amount: number;
  sku: setCartItems_setCartItems_sku;
}

export interface setCartItems {
  setCartItems: setCartItems_setCartItems[];
}

export interface setCartItemsVariables {
  cartItemInputs: CartItemInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addCartItems
// ====================================================

export interface addCartItems_addCartItems_sku_photo_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface addCartItems_addCartItems_sku_photo {
  __typename: 'Photo';
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: addCartItems_addCartItems_sku_photo_link;
}

export interface addCartItems_addCartItems_sku_characteristicValues_characteristic {
  __typename: 'ProductCharacteristic';
  /**
   * Заголовок характеристики
   */
  title: string;
}

export interface addCartItems_addCartItems_sku_characteristicValues {
  __typename: 'CharacteristicValue';
  /**
   * Заголовок значения характеристики
   */
  title: string;
  /**
   * Для хар-ки цвет - RGB значение
   */
  value: string;
  /**
   * Характеристика
   */
  characteristic: addCartItems_addCartItems_sku_characteristicValues_characteristic;
}

export interface addCartItems_addCartItems_sku_product {
  __typename: 'Product';
  /**
   * ID продукта
   */
  id: number;
  /**
   * Название продукта
   */
  title: string;
  /**
   * Краткое описание продукта
   */
  shortDescription: string | null;
}

export interface addCartItems_addCartItems_sku {
  __typename: 'Sku';
  /**
   * Доступное количество по SKU
   */
  availableAmount: number;
  /**
   * ID SKU
   */
  id: number;
  /**
   * Стоимость SKU без скидки (зачеркнутая цена)
   */
  fullPrice: number;
  /**
   * Цена продажи СКУ
   */
  sellPrice: number;
  /**
   * SKU title, не используется на клиентах
   */
  skuTitle: string;
  /**
   * Основная фотография для СКУ
   */
  photo: addCartItems_addCartItems_sku_photo;
  /**
   * Значения характеристик SKU
   */
  characteristicValues: addCartItems_addCartItems_sku_characteristicValues[];
  /**
   * Продукт, к которому относится SKU
   */
  product: addCartItems_addCartItems_sku_product;
  /**
   * Вес SKU
   */
  weight: number | null;
}

export interface addCartItems_addCartItems {
  __typename: 'CartItem';
  amount: number;
  sku: addCartItems_addCartItems_sku;
}

export interface addCartItems {
  addCartItems: addCartItems_addCartItems[];
}

export interface addCartItemsVariables {
  cartItemInputs: CartItemInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createPsbOrder
// ====================================================

export interface createPsbOrder_createPsbOrder_orderItems {
  __typename: 'OrderItem';
  /**
   * ID позиции заказа
   */
  id: number;
  /**
   * Статус по позиции в заказе
   */
  status: OrderItemStatus | null;
  /**
   * Количество выбранного SKU в заказе
   */
  amount: number;
  /**
   * Цена покупки для одной штуки (без учета промокодов)
   */
  purchasePrice: number;
  /**
   * Цена оплаты для одной штуки
   */
  paymentPrice: number;
  /**
   * Количество, сколько вернули
   */
  returnedAmount: number;
}

export interface createPsbOrder_createPsbOrder {
  __typename: 'Order';
  id: number;
  paymentUrl: string | null;
  issueCode: number | null;
  price: number;
  status: OrderStatus;
  orderItems: createPsbOrder_createPsbOrder_orderItems[];
}

export interface createPsbOrder {
  createPsbOrder: createPsbOrder_createPsbOrder | null;
}

export interface createPsbOrderVariables {
  orderInput: CreateOrderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getProductSkuList
// ====================================================

export interface getProductSkuList_product_skuList_product {
  __typename: 'Product';
  /**
   * ID продукта
   */
  id: number;
  /**
   * Название продукта
   */
  title: string;
  /**
   * Краткое описание продукта
   */
  shortDescription: string | null;
}

export interface getProductSkuList_product_skuList_characteristicValues_characteristic {
  __typename: 'ProductCharacteristic';
  /**
   * ID характеристики
   */
  id: number;
  /**
   * Заголовок характеристики
   */
  title: string;
}

export interface getProductSkuList_product_skuList_characteristicValues {
  __typename: 'CharacteristicValue';
  /**
   * Характеристика
   */
  characteristic: getProductSkuList_product_skuList_characteristicValues_characteristic;
  /**
   * Заголовок значения характеристики
   */
  title: string;
  /**
   * Для хар-ки цвет - RGB значение
   */
  value: string;
}

export interface getProductSkuList_product_skuList_photos_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface getProductSkuList_product_skuList_photos {
  __typename: 'Photo';
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: getProductSkuList_product_skuList_photos_link;
}

export interface getProductSkuList_product_skuList {
  __typename: 'Sku';
  /**
   * ID SKU
   */
  id: number;
  /**
   * Продукт, к которому относится SKU
   */
  product: getProductSkuList_product_skuList_product;
  /**
   * Доступное количество по SKU
   */
  availableAmount: number;
  /**
   * Значения характеристик SKU
   */
  characteristicValues: getProductSkuList_product_skuList_characteristicValues[];
  /**
   * Цена продажи СКУ
   */
  sellPrice: number;
  /**
   * Стоимость SKU без скидки (зачеркнутая цена)
   */
  fullPrice: number;
  /**
   * Массив фотографий для СКУ
   */
  photos: getProductSkuList_product_skuList_photos[];
  /**
   * Вес SKU
   */
  weight: number | null;
}

export interface getProductSkuList_product {
  __typename: 'Product';
  /**
   * Массив SKU
   */
  skuList: getProductSkuList_product_skuList[];
}

export interface getProductSkuList {
  product: getProductSkuList_product;
}

export interface getProductSkuListVariables {
  productId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAuthCart
// ====================================================

export interface getAuthCart_cart_sku_product {
  __typename: 'Product';
  /**
   * ID продукта
   */
  id: number;
  /**
   * Название продукта
   */
  title: string;
  /**
   * Краткое описание продукта
   */
  shortDescription: string | null;
}

export interface getAuthCart_cart_sku_characteristicValues_characteristic {
  __typename: 'ProductCharacteristic';
  /**
   * ID характеристики
   */
  id: number;
  /**
   * Заголовок характеристики
   */
  title: string;
}

export interface getAuthCart_cart_sku_characteristicValues {
  __typename: 'CharacteristicValue';
  /**
   * Характеристика
   */
  characteristic: getAuthCart_cart_sku_characteristicValues_characteristic;
  /**
   * Заголовок значения характеристики
   */
  title: string;
  /**
   * Для хар-ки цвет - RGB значение
   */
  value: string;
}

export interface getAuthCart_cart_sku_photos_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface getAuthCart_cart_sku_photos {
  __typename: 'Photo';
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: getAuthCart_cart_sku_photos_link;
}

export interface getAuthCart_cart_sku {
  __typename: 'Sku';
  /**
   * ID SKU
   */
  id: number;
  /**
   * Продукт, к которому относится SKU
   */
  product: getAuthCart_cart_sku_product;
  /**
   * Доступное количество по SKU
   */
  availableAmount: number;
  /**
   * Значения характеристик SKU
   */
  characteristicValues: getAuthCart_cart_sku_characteristicValues[];
  /**
   * Цена продажи СКУ
   */
  sellPrice: number;
  /**
   * Стоимость SKU без скидки (зачеркнутая цена)
   */
  fullPrice: number;
  /**
   * Массив фотографий для СКУ
   */
  photos: getAuthCart_cart_sku_photos[];
  /**
   * Вес SKU
   */
  weight: number | null;
}

export interface getAuthCart_cart {
  __typename: 'CartItem';
  amount: number;
  sku: getAuthCart_cart_sku;
}

export interface getAuthCart {
  cart: getAuthCart_cart[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckPromoCode
// ====================================================

export interface CheckPromoCode_checkPromoCode {
  __typename: 'PromoCodeResponse';
  discountAmount: number | null;
  message: string | null;
  success: boolean;
}

export interface CheckPromoCode {
  checkPromoCode: CheckPromoCode_checkPromoCode;
}

export interface CheckPromoCodeVariables {
  input: PromoCodeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DshopMain
// ====================================================

export interface DshopMain_dshopMain_offers_productsPage_content_photos_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface DshopMain_dshopMain_offers_productsPage_content_photos {
  __typename: 'Photo';
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: DshopMain_dshopMain_offers_productsPage_content_photos_link;
}

export interface DshopMain_dshopMain_offers_productsPage_content_skuList {
  __typename: 'Sku';
  /**
   * ID SKU
   */
  id: number;
  /**
   * Доступное количество по SKU
   */
  availableAmount: number;
  /**
   * Цена продажи СКУ
   */
  sellPrice: number;
  /**
   * Стоимость SKU без скидки (зачеркнутая цена)
   */
  fullPrice: number;
}

export interface DshopMain_dshopMain_offers_productsPage_content_badges_BottomIconTextBadge {
  __typename: 'BottomIconTextBadge' | 'TopTextBadge';
}

export interface DshopMain_dshopMain_offers_productsPage_content_badges_BottomTextBadge {
  __typename: 'BottomTextBadge';
  id: number;
  text: string;
  textColor: string;
  backgroundColor: string;
}

export type DshopMain_dshopMain_offers_productsPage_content_badges =
  | DshopMain_dshopMain_offers_productsPage_content_badges_BottomIconTextBadge
  | DshopMain_dshopMain_offers_productsPage_content_badges_BottomTextBadge;

export interface DshopMain_dshopMain_offers_productsPage_content {
  __typename: 'Product';
  /**
   * ID продукта
   */
  id: number;
  /**
   * Название продукта
   */
  title: string;
  /**
   * Краткое описание продукта
   */
  shortDescription: string | null;
  /**
   * Рейтинг товара
   */
  rating: number;
  /**
   * Количество отзывов
   */
  feedbackQuantity: number;
  /**
   * Массив всех фотографий товара
   */
  photos: DshopMain_dshopMain_offers_productsPage_content_photos[];
  /**
   * Массив SKU
   */
  skuList: DshopMain_dshopMain_offers_productsPage_content_skuList[];
  /**
   * Массив бейджей товара
   */
  badges: DshopMain_dshopMain_offers_productsPage_content_badges[];
}

export interface DshopMain_dshopMain_offers_productsPage {
  __typename: 'ProductsPage';
  /**
   * Массив продуктов
   */
  content: DshopMain_dshopMain_offers_productsPage_content[];
}

export interface DshopMain_dshopMain_offers {
  __typename: 'DshopOffer';
  id: number;
  title: string;
  productsPage: DshopMain_dshopMain_offers_productsPage;
}

export interface DshopMain_dshopMain_dshopTexts_additionalTexts {
  __typename: 'AdditionalText';
  /**
   * Текст
   */
  value: string | null;
}

export interface DshopMain_dshopMain_dshopTexts {
  __typename: 'DshopTexts';
  /**
   * Другие тексты
   */
  additionalTexts: (DshopMain_dshopMain_dshopTexts_additionalTexts | null)[] | null;
}

export interface DshopMain_dshopMain_banners_image {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface DshopMain_dshopMain_banners {
  __typename: 'DshopBanner';
  description: string | null;
  link: string | null;
  title: string;
  /**
   * Свойство в ссылках на фронте, оно отвечает в каком окне открывать ссылку (текущем, новом)
   */
  target: string | null;
  /**
   * Параметры
   */
  params: string | null;
  image: DshopMain_dshopMain_banners_image;
}

export interface DshopMain_dshopMain_cities_deliveryPoints {
  __typename: 'DeliveryPoint';
  /**
   * ID ПВЗ
   */
  id: number;
  /**
   * Адрес ПВЗ
   */
  address: string;
  /**
   * Ближайшая дата доставки
   */
  deliveryDate: any | null;
  /**
   * Есть ли примерочная
   */
  hasDressingRoom: boolean;
  /**
   * Широта
   */
  latitude: number;
  /**
   * Долгота
   */
  longitude: number;
  /**
   * Время начала работы ПВЗ
   */
  timeFrom: string;
  /**
   * Время окончания работы ПВЗ
   */
  timeTo: string;
}

export interface DshopMain_dshopMain_cities {
  __typename: 'City';
  /**
   * ID города
   */
  id: number;
  /**
   * Название города
   */
  name: string;
  /**
   * Минимальная стоимость заказа для бесплатной доставки курьером
   */
  minFreeDeliveryPriceCourier: number;
  /**
   * Минимальная стоимость заказа для доставки до ПВЗ
   */
  minFreeDeliveryPriceDp: number;
  /**
   * Список ПВЗ
   */
  deliveryPoints: DshopMain_dshopMain_cities_deliveryPoints[];
}

export interface DshopMain_dshopMain_promoCategories {
  __typename: 'PromoCategory';
  deepLink: string;
  title: string;
  subtitle: string | null;
}

export interface DshopMain_dshopMain_categories_image {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
  /**
   * В низком качестве
   */
  low: string;
}

export interface DshopMain_dshopMain_categories_children_children {
  __typename: 'Category';
  /**
   * Category identifier.
   */
  id: number;
  title: string;
}

export interface DshopMain_dshopMain_categories_children {
  __typename: 'Category';
  title: string;
  /**
   * Category identifier.
   */
  id: number;
  children: DshopMain_dshopMain_categories_children_children[];
}

export interface DshopMain_dshopMain_categories {
  __typename: 'Category';
  /**
   * Category identifier.
   */
  id: number;
  title: string;
  image: DshopMain_dshopMain_categories_image | null;
  children: DshopMain_dshopMain_categories_children[];
}

export interface DshopMain_dshopMain {
  __typename: 'DshopMain';
  offers: DshopMain_dshopMain_offers[];
  dshopTexts: DshopMain_dshopMain_dshopTexts | null;
  banners: DshopMain_dshopMain_banners[];
  cities: DshopMain_dshopMain_cities[];
  promoCategories: (DshopMain_dshopMain_promoCategories | null)[] | null;
  categories: DshopMain_dshopMain_categories[];
}

export interface DshopMain_deliveryInfo {
  __typename: 'DeliveryInfo';
  freeDeliveryMinPrice: number;
}

export interface DshopMain {
  dshopMain: DshopMain_dshopMain;
  deliveryInfo: DshopMain_deliveryInfo;
}

export interface DshopMainVariables {
  productsSize: number;
  displayType: DisplayType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: expectedDelivery
// ====================================================

export interface expectedDelivery_expectedDelivery {
  __typename: 'ExpectedDelivery';
  /**
   * Максимальное кол-во дней через сколько доставят
   */
  maxDays: number | null;
  /**
   * Стоимость доставки
   */
  price: number;
}

export interface expectedDelivery {
  expectedDelivery: expectedDelivery_expectedDelivery | null;
}

export interface expectedDeliveryVariables {
  deliveryInput: ExpectedDeliveryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Category
// ====================================================

export interface Category_category_parentList {
  __typename: 'Category';
  /**
   * Category identifier.
   */
  id: number;
  title: string;
}

export interface Category_category_children {
  __typename: 'Category';
  /**
   * Category identifier.
   */
  id: number;
  title: string;
}

export interface Category_category {
  __typename: 'Category';
  /**
   * Category identifier.
   */
  id: number;
  title: string;
  parentList: Category_category_parentList[];
  children: Category_category_children[];
}

export interface Category {
  category: Category_category;
}

export interface CategoryVariables {
  categoryId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Options
// ====================================================

export interface Options_calcCourierDeliveryOffers_options {
  __typename: 'DeliveryOption';
  /**
   * Время начала таймслота
   */
  from: any;
  /**
   * ID таймслота
   */
  id: number;
  /**
   * Стоимость доставки в этот таймслот
   */
  price: number;
  /**
   * Время окончания таймслота
   */
  to: any;
}

export interface Options_calcCourierDeliveryOffers {
  __typename: 'DeliveryOffer';
  options: Options_calcCourierDeliveryOffers_options[];
  date: any;
}

export interface Options {
  calcCourierDeliveryOffers: Options_calcCourierDeliveryOffers[];
}

export interface OptionsVariables {
  orderInput: CreateOrderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Product
// ====================================================

export interface Product_product_photos_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface Product_product_photos_original {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface Product_product_photos {
  __typename: 'Photo';
  /**
   * Ключ фотографии
   */
  key: string;
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: Product_product_photos_link;
  /**
   * Ссылка на оригинал фотографии
   */
  original: Product_product_photos_original;
}

export interface Product_product_category_parentList {
  __typename: 'Category';
  title: string;
  /**
   * Category identifier.
   */
  id: number;
}

export interface Product_product_category {
  __typename: 'Category';
  title: string;
  /**
   * Category identifier.
   */
  id: number;
  parentList: Product_product_category_parentList[];
}

export interface Product_product_skuList_photo_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface Product_product_skuList_photo {
  __typename: 'Photo';
  /**
   * Ключ фотографии
   */
  key: string;
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: Product_product_skuList_photo_link;
}

export interface Product_product_skuList_characteristicValues_characteristic {
  __typename: 'ProductCharacteristic';
  /**
   * ID характеристики
   */
  id: number;
  /**
   * Заголовок характеристики
   */
  title: string;
}

export interface Product_product_skuList_characteristicValues {
  __typename: 'CharacteristicValue';
  /**
   * Заголовок значения характеристики
   */
  title: string;
  /**
   * Для хар-ки цвет - RGB значение
   */
  value: string;
  /**
   * ID значения
   */
  id: number;
  /**
   * Характеристика
   */
  characteristic: Product_product_skuList_characteristicValues_characteristic;
}

export interface Product_product_skuList {
  __typename: 'Sku';
  /**
   * ID SKU
   */
  id: number;
  /**
   * Стоимость SKU без скидки (зачеркнутая цена)
   */
  fullPrice: number;
  /**
   * Цена продажи СКУ
   */
  sellPrice: number;
  /**
   * SKU title, не используется на клиентах
   */
  skuTitle: string;
  /**
   * Доступное количество по SKU
   */
  availableAmount: number;
  /**
   * Вес SKU
   */
  weight: number | null;
  /**
   * Основная фотография для СКУ
   */
  photo: Product_product_skuList_photo;
  /**
   * Значения характеристик SKU
   */
  characteristicValues: Product_product_skuList_characteristicValues[];
}

export interface Product_product_comments {
  __typename: 'ProductComment';
  type: string;
  value: string;
}

export interface Product_product_characteristics_values_photo_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface Product_product_characteristics_values_photo_original {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface Product_product_characteristics_values_photo {
  __typename: 'Photo';
  /**
   * Ключ фотографии
   */
  key: string;
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: Product_product_characteristics_values_photo_link;
  /**
   * Ссылка на оригинал фотографии
   */
  original: Product_product_characteristics_values_photo_original;
}

export interface Product_product_characteristics_values {
  __typename: 'CharacteristicValue';
  /**
   * ID значения
   */
  id: number;
  /**
   * Заголовок значения характеристики
   */
  title: string;
  /**
   * Для хар-ки цвет - RGB значение
   */
  value: string;
  /**
   * Фото значения характеристики
   */
  photo: Product_product_characteristics_values_photo | null;
}

export interface Product_product_characteristics {
  __typename: 'ProductCharacteristic';
  /**
   * ID характеристики
   */
  id: number;
  /**
   * Заголовок характеристики
   */
  title: string;
  /**
   * Массив значений по характеристике
   */
  values: Product_product_characteristics_values[];
}

export interface Product_product_badges_BottomIconTextBadge {
  __typename: 'BottomIconTextBadge' | 'TopTextBadge';
}

export interface Product_product_badges_BottomTextBadge {
  __typename: 'BottomTextBadge';
  id: number;
  text: string;
  textColor: string;
  backgroundColor: string;
}

export type Product_product_badges =
  | Product_product_badges_BottomIconTextBadge
  | Product_product_badges_BottomTextBadge;

export interface Product_product {
  __typename: 'Product';
  /**
   * ID продукта
   */
  id: number;
  /**
   * Развернутое описание продукта
   */
  description: string;
  /**
   * Название продукта
   */
  title: string;
  /**
   * Краткое описание продукта
   */
  shortDescription: string | null;
  /**
   * Массив всех фотографий товара
   */
  photos: Product_product_photos[];
  /**
   * Информация о категории продукта
   */
  category: Product_product_category | null;
  /**
   * Массив SKU
   */
  skuList: Product_product_skuList[];
  /**
   * Массив содержащий размерную сетку, инструкцию, состав, сертификацию
   */
  comments: Product_product_comments[];
  /**
   * Массив характеристик продукта
   */
  characteristics: Product_product_characteristics[];
  /**
   * Массив бейджей товара
   */
  badges: Product_product_badges[];
}

export interface Product {
  product: Product_product;
}

export interface ProductVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductPageFeedbackPhotos
// ====================================================

export interface ProductPageFeedbackPhotos_productPage_feedbacks_photos_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface ProductPageFeedbackPhotos_productPage_feedbacks_photos_original {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface ProductPageFeedbackPhotos_productPage_feedbacks_photos {
  __typename: 'Photo';
  /**
   * Ключ фотографии
   */
  key: string;
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: ProductPageFeedbackPhotos_productPage_feedbacks_photos_link;
  /**
   * Ссылка на оригинал фотографии
   */
  original: ProductPageFeedbackPhotos_productPage_feedbacks_photos_original;
}

export interface ProductPageFeedbackPhotos_productPage_feedbacks {
  __typename: 'Feedback';
  /**
   * Фотографии отзыва
   */
  photos: ProductPageFeedbackPhotos_productPage_feedbacks_photos[] | null;
}

export interface ProductPageFeedbackPhotos_productPage {
  __typename: 'ProductPage';
  feedbacks: ProductPageFeedbackPhotos_productPage_feedbacks[] | null;
}

export interface ProductPageFeedbackPhotos {
  /**
   * Запрос на получение данных по странице товара
   */
  productPage: ProductPageFeedbackPhotos_productPage;
}

export interface ProductPageFeedbackPhotosVariables {
  productPageId: number;
  filters?: FeedbackFilterType[] | null;
  page: number;
  size: number;
  sort: FeedbackSortType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductPageFeedback
// ====================================================

export interface ProductPageFeedback_productPage_feedbacks_reply_shop_avatar {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface ProductPageFeedback_productPage_feedbacks_reply_shop {
  __typename: 'Shop';
  /**
   * Название магазина
   */
  title: string;
  /**
   * Аватарка магазина
   */
  avatar: ProductPageFeedback_productPage_feedbacks_reply_shop_avatar | null;
}

export interface ProductPageFeedback_productPage_feedbacks_reply {
  __typename: 'FeedbackReply';
  /**
   * ID ответа на отзыв
   */
  id: number;
  /**
   * Содержание ответа
   */
  content: string | null;
  /**
   * Дата создания ответа на отзыв
   */
  dateCreated: any | null;
  /**
   * Шоп который ответил на отзыв
   */
  shop: ProductPageFeedback_productPage_feedbacks_reply_shop | null;
}

export interface ProductPageFeedback_productPage_feedbacks_photos_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface ProductPageFeedback_productPage_feedbacks_photos_original {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface ProductPageFeedback_productPage_feedbacks_photos {
  __typename: 'Photo';
  /**
   * Ключ фотографии
   */
  key: string;
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: ProductPageFeedback_productPage_feedbacks_photos_link;
  /**
   * Ссылка на оригинал фотографии
   */
  original: ProductPageFeedback_productPage_feedbacks_photos_original;
}

export interface ProductPageFeedback_productPage_feedbacks_sku_characteristicValues_characteristic {
  __typename: 'ProductCharacteristic';
  /**
   * ID характеристики
   */
  id: number;
  /**
   * Заголовок характеристики
   */
  title: string;
}

export interface ProductPageFeedback_productPage_feedbacks_sku_characteristicValues {
  __typename: 'CharacteristicValue';
  /**
   * Характеристика
   */
  characteristic: ProductPageFeedback_productPage_feedbacks_sku_characteristicValues_characteristic;
  /**
   * ID значения
   */
  id: number;
  /**
   * Заголовок значения характеристики
   */
  title: string;
  /**
   * Для хар-ки цвет - RGB значение
   */
  value: string;
}

export interface ProductPageFeedback_productPage_feedbacks_sku {
  __typename: 'Sku';
  /**
   * Значения характеристик SKU
   */
  characteristicValues: ProductPageFeedback_productPage_feedbacks_sku_characteristicValues[];
}

export interface ProductPageFeedback_productPage_feedbacks {
  __typename: 'Feedback';
  /**
   * ID отзыва
   */
  id: number;
  /**
   * Анонимный ли отзыв
   */
  anonymous: boolean;
  /**
   * Имя автора отзыва
   */
  customerName: string;
  /**
   * Комментарий
   */
  content: string | null;
  /**
   * Плюсы
   */
  pros: string | null;
  /**
   * Минусы
   */
  cons: string | null;
  /**
   * Дата публикации отзыва
   */
  datePublished: any | null;
  /**
   * Рейтинг
   */
  rating: number;
  /**
   * Ответ на отзыв
   */
  reply: ProductPageFeedback_productPage_feedbacks_reply[] | null;
  /**
   * Фотографии отзыва
   */
  photos: ProductPageFeedback_productPage_feedbacks_photos[] | null;
  /**
   * Ску на который был оствлен отзыв
   */
  sku: ProductPageFeedback_productPage_feedbacks_sku;
}

export interface ProductPageFeedback_productPage {
  __typename: 'ProductPage';
  feedbacks: ProductPageFeedback_productPage_feedbacks[] | null;
}

export interface ProductPageFeedback {
  /**
   * Запрос на получение данных по странице товара
   */
  productPage: ProductPageFeedback_productPage;
}

export interface ProductPageFeedbackVariables {
  productPageId: number;
  filters?: FeedbackFilterType[] | null;
  page: number;
  size: number;
  sort: FeedbackSortType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductPage
// ====================================================

export interface ProductPage_productPage_product_photos_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface ProductPage_productPage_product_photos_original {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface ProductPage_productPage_product_photos {
  __typename: 'Photo';
  /**
   * Ключ фотографии
   */
  key: string;
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: ProductPage_productPage_product_photos_link;
  /**
   * Ссылка на оригинал фотографии
   */
  original: ProductPage_productPage_product_photos_original;
}

export interface ProductPage_productPage_product_category_parentList {
  __typename: 'Category';
  /**
   * Category identifier.
   */
  id: number;
  title: string;
}

export interface ProductPage_productPage_product_category {
  __typename: 'Category';
  /**
   * Category identifier.
   */
  id: number;
  title: string;
  parentList: ProductPage_productPage_product_category_parentList[];
}

export interface ProductPage_productPage_product_skuList_photo_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface ProductPage_productPage_product_skuList_photo {
  __typename: 'Photo';
  /**
   * Ключ фотографии
   */
  key: string;
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: ProductPage_productPage_product_skuList_photo_link;
}

export interface ProductPage_productPage_product_skuList_characteristicValues_characteristic {
  __typename: 'ProductCharacteristic';
  /**
   * ID характеристики
   */
  id: number;
  /**
   * Заголовок характеристики
   */
  title: string;
}

export interface ProductPage_productPage_product_skuList_characteristicValues {
  __typename: 'CharacteristicValue';
  /**
   * ID значения
   */
  id: number;
  /**
   * Заголовок значения характеристики
   */
  title: string;
  /**
   * Для хар-ки цвет - RGB значение
   */
  value: string;
  /**
   * Характеристика
   */
  characteristic: ProductPage_productPage_product_skuList_characteristicValues_characteristic;
}

export interface ProductPage_productPage_product_skuList {
  __typename: 'Sku';
  /**
   * ID SKU
   */
  id: number;
  /**
   * Стоимость SKU без скидки (зачеркнутая цена)
   */
  fullPrice: number;
  /**
   * Цена продажи СКУ
   */
  sellPrice: number;
  /**
   * SKU title, не используется на клиентах
   */
  skuTitle: string;
  /**
   * Доступное количество по SKU
   */
  availableAmount: number;
  /**
   * Вес SKU
   */
  weight: number | null;
  /**
   * Основная фотография для СКУ
   */
  photo: ProductPage_productPage_product_skuList_photo;
  /**
   * Значения характеристик SKU
   */
  characteristicValues: ProductPage_productPage_product_skuList_characteristicValues[];
}

export interface ProductPage_productPage_product_comments {
  __typename: 'ProductComment';
  type: string;
  value: string;
}

export interface ProductPage_productPage_product_characteristics_values_photo_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface ProductPage_productPage_product_characteristics_values_photo_original {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface ProductPage_productPage_product_characteristics_values_photo {
  __typename: 'Photo';
  /**
   * Ключ фотографии
   */
  key: string;
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: ProductPage_productPage_product_characteristics_values_photo_link;
  /**
   * Ссылка на оригинал фотографии
   */
  original: ProductPage_productPage_product_characteristics_values_photo_original;
}

export interface ProductPage_productPage_product_characteristics_values {
  __typename: 'CharacteristicValue';
  /**
   * ID значения
   */
  id: number;
  /**
   * Заголовок значения характеристики
   */
  title: string;
  /**
   * Для хар-ки цвет - RGB значение
   */
  value: string;
  /**
   * Фото значения характеристики
   */
  photo: ProductPage_productPage_product_characteristics_values_photo | null;
}

export interface ProductPage_productPage_product_characteristics {
  __typename: 'ProductCharacteristic';
  /**
   * ID характеристики
   */
  id: number;
  /**
   * Заголовок характеристики
   */
  title: string;
  /**
   * Массив значений по характеристике
   */
  values: ProductPage_productPage_product_characteristics_values[];
}

export interface ProductPage_productPage_product_badges_BottomIconTextBadge {
  __typename: 'BottomIconTextBadge' | 'TopTextBadge';
}

export interface ProductPage_productPage_product_badges_BottomTextBadge {
  __typename: 'BottomTextBadge';
  id: number;
  text: string;
  textColor: string;
  backgroundColor: string;
}

export type ProductPage_productPage_product_badges =
  | ProductPage_productPage_product_badges_BottomIconTextBadge
  | ProductPage_productPage_product_badges_BottomTextBadge;

export interface ProductPage_productPage_product {
  __typename: 'Product';
  /**
   * ID продукта
   */
  id: number;
  /**
   * Название продукта
   */
  title: string;
  /**
   * Развернутое описание продукта
   */
  description: string;
  /**
   * Краткое описание продукта
   */
  shortDescription: string | null;
  /**
   * Свойства товара
   */
  attributes: string[];
  /**
   * Массив всех фотографий товара
   */
  photos: ProductPage_productPage_product_photos[];
  /**
   * Информация о категории продукта
   */
  category: ProductPage_productPage_product_category | null;
  /**
   * Массив SKU
   */
  skuList: ProductPage_productPage_product_skuList[];
  /**
   * Массив содержащий размерную сетку, инструкцию, состав, сертификацию
   */
  comments: ProductPage_productPage_product_comments[];
  /**
   * Массив характеристик продукта
   */
  characteristics: ProductPage_productPage_product_characteristics[];
  /**
   * Количество отзывов
   */
  feedbackQuantity: number;
  /**
   * Рейтинг товара
   */
  rating: number;
  /**
   * Массив бейджей товара
   */
  badges: ProductPage_productPage_product_badges[];
}

export interface ProductPage_productPage_feedbacks_reply_shop_avatar {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface ProductPage_productPage_feedbacks_reply_shop {
  __typename: 'Shop';
  /**
   * Название магазина
   */
  title: string;
  /**
   * Аватарка магазина
   */
  avatar: ProductPage_productPage_feedbacks_reply_shop_avatar | null;
}

export interface ProductPage_productPage_feedbacks_reply {
  __typename: 'FeedbackReply';
  /**
   * ID ответа на отзыв
   */
  id: number;
  /**
   * Содержание ответа
   */
  content: string | null;
  /**
   * Дата создания ответа на отзыв
   */
  dateCreated: any | null;
  /**
   * Шоп который ответил на отзыв
   */
  shop: ProductPage_productPage_feedbacks_reply_shop | null;
}

export interface ProductPage_productPage_feedbacks_photos_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface ProductPage_productPage_feedbacks_photos_original {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface ProductPage_productPage_feedbacks_photos {
  __typename: 'Photo';
  /**
   * Ключ фотографии
   */
  key: string;
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: ProductPage_productPage_feedbacks_photos_link;
  /**
   * Ссылка на оригинал фотографии
   */
  original: ProductPage_productPage_feedbacks_photos_original;
}

export interface ProductPage_productPage_feedbacks_sku_characteristicValues_characteristic {
  __typename: 'ProductCharacteristic';
  /**
   * ID характеристики
   */
  id: number;
  /**
   * Заголовок характеристики
   */
  title: string;
}

export interface ProductPage_productPage_feedbacks_sku_characteristicValues {
  __typename: 'CharacteristicValue';
  /**
   * Характеристика
   */
  characteristic: ProductPage_productPage_feedbacks_sku_characteristicValues_characteristic;
  /**
   * ID значения
   */
  id: number;
  /**
   * Заголовок значения характеристики
   */
  title: string;
  /**
   * Для хар-ки цвет - RGB значение
   */
  value: string;
}

export interface ProductPage_productPage_feedbacks_sku {
  __typename: 'Sku';
  /**
   * Значения характеристик SKU
   */
  characteristicValues: ProductPage_productPage_feedbacks_sku_characteristicValues[];
}

export interface ProductPage_productPage_feedbacks {
  __typename: 'Feedback';
  /**
   * ID отзыва
   */
  id: number;
  /**
   * Анонимный ли отзыв
   */
  anonymous: boolean;
  /**
   * Имя автора отзыва
   */
  customerName: string;
  /**
   * Комментарий
   */
  content: string | null;
  /**
   * Плюсы
   */
  pros: string | null;
  /**
   * Минусы
   */
  cons: string | null;
  /**
   * Дата публикации отзыва
   */
  datePublished: any | null;
  /**
   * Рейтинг
   */
  rating: number;
  /**
   * Ответ на отзыв
   */
  reply: ProductPage_productPage_feedbacks_reply[] | null;
  /**
   * Фотографии отзыва
   */
  photos: ProductPage_productPage_feedbacks_photos[] | null;
  /**
   * Ску на который был оствлен отзыв
   */
  sku: ProductPage_productPage_feedbacks_sku;
}

export interface ProductPage_productPage {
  __typename: 'ProductPage';
  /**
   * ID продукта
   */
  id: number;
  /**
   * Сам продукт
   */
  product: ProductPage_productPage_product;
  feedbacks: ProductPage_productPage_feedbacks[] | null;
}

export interface ProductPage {
  /**
   * Запрос на получение данных по странице товара
   */
  productPage: ProductPage_productPage;
}

export interface ProductPageVariables {
  productPageId: number;
  filters?: FeedbackFilterType[] | null;
  page: number;
  size: number;
  sort: FeedbackSortType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSuggestions
// ====================================================

export interface GetSuggestions_getSuggestions_blocks_CatalogCardSuggestionsBlock {
  __typename:
    | 'CatalogCardSuggestionsBlock'
    | 'CategorySuggestionsBlock'
    | 'ShopSuggestionsBlock'
    | 'RecommendedSuggestionsBlock'
    | 'SearchedEarlierBlock'
    | 'LookedEarlierBlock';
}

export interface GetSuggestions_getSuggestions_blocks_PopularSuggestionsBlock {
  __typename: 'PopularSuggestionsBlock';
  /**
   * List of popular suggestions.
   */
  popularSuggestions: string[] | null;
}

export interface GetSuggestions_getSuggestions_blocks_TextSuggestionsBlock {
  __typename: 'TextSuggestionsBlock';
  /**
   * List of text suggestions.
   */
  values: string[] | null;
}

export type GetSuggestions_getSuggestions_blocks =
  | GetSuggestions_getSuggestions_blocks_CatalogCardSuggestionsBlock
  | GetSuggestions_getSuggestions_blocks_PopularSuggestionsBlock
  | GetSuggestions_getSuggestions_blocks_TextSuggestionsBlock;

export interface GetSuggestions_getSuggestions {
  __typename: 'GetSuggestionsResult';
  /**
   * Suggestions blocks.
   */
  blocks: (GetSuggestions_getSuggestions_blocks | null)[] | null;
}

export interface GetSuggestions {
  /**
   * Get search suggestions.
   */
  getSuggestions: GetSuggestions_getSuggestions;
}

export interface GetSuggestionsVariables {
  getSuggestionsInput: GetSuggestionsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DShopOffer
// ====================================================

export interface DShopOffer_dshopOffer {
  __typename: 'DshopOffer';
  id: number;
  title: string;
}

export interface DShopOffer {
  dshopOffer: DShopOffer_dshopOffer;
}

export interface DShopOfferVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DshopOffer
// ====================================================

export interface DshopOffer_dshopOffer_productsPage_content_skuList {
  __typename: 'Sku';
  /**
   * Цена продажи СКУ
   */
  sellPrice: number;
  /**
   * Стоимость SKU без скидки (зачеркнутая цена)
   */
  fullPrice: number;
  /**
   * ID SKU
   */
  id: number;
}

export interface DshopOffer_dshopOffer_productsPage_content_photos_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface DshopOffer_dshopOffer_productsPage_content_photos {
  __typename: 'Photo';
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: DshopOffer_dshopOffer_productsPage_content_photos_link;
}

export interface DshopOffer_dshopOffer_productsPage_content_badges_BottomIconTextBadge {
  __typename: 'BottomIconTextBadge' | 'TopTextBadge';
}

export interface DshopOffer_dshopOffer_productsPage_content_badges_BottomTextBadge {
  __typename: 'BottomTextBadge';
  id: number;
  text: string;
  textColor: string;
  backgroundColor: string;
}

export type DshopOffer_dshopOffer_productsPage_content_badges =
  | DshopOffer_dshopOffer_productsPage_content_badges_BottomIconTextBadge
  | DshopOffer_dshopOffer_productsPage_content_badges_BottomTextBadge;

export interface DshopOffer_dshopOffer_productsPage_content {
  __typename: 'Product';
  /**
   * ID продукта
   */
  id: number;
  /**
   * Название продукта
   */
  title: string;
  /**
   * Краткое описание продукта
   */
  shortDescription: string | null;
  /**
   * Рейтинг товара
   */
  rating: number;
  /**
   * Количество отзывов
   */
  feedbackQuantity: number;
  /**
   * Массив SKU
   */
  skuList: DshopOffer_dshopOffer_productsPage_content_skuList[];
  /**
   * Массив всех фотографий товара
   */
  photos: DshopOffer_dshopOffer_productsPage_content_photos[];
  /**
   * Массив бейджей товара
   */
  badges: DshopOffer_dshopOffer_productsPage_content_badges[];
}

export interface DshopOffer_dshopOffer_productsPage {
  __typename: 'ProductsPage';
  /**
   * Массив продуктов
   */
  content: DshopOffer_dshopOffer_productsPage_content[];
}

export interface DshopOffer_dshopOffer {
  __typename: 'DshopOffer';
  id: number;
  title: string;
  productsPage: DshopOffer_dshopOffer_productsPage;
}

export interface DshopOffer {
  dshopOffer: DshopOffer_dshopOffer;
}

export interface DshopOfferVariables {
  offerId: number;
  page: number;
  size: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOrder
// ====================================================

export interface getOrder_order_delivery_MetashipDelivery {
  __typename: 'MetashipDelivery';
  price: number;
}

export interface getOrder_order_delivery_CourierDelivery_city {
  __typename: 'City';
  /**
   * ID города
   */
  id: number;
}

export interface getOrder_order_delivery_CourierDelivery {
  __typename: 'CourierDelivery';
  price: number;
  address: string;
  todayDelivery: boolean;
  city: getOrder_order_delivery_CourierDelivery_city;
}

export interface getOrder_order_delivery_DpDelivery_deliveryPoint {
  __typename: 'DeliveryPoint';
  /**
   * ID ПВЗ
   */
  id: number;
  /**
   * Адрес ПВЗ
   */
  address: string;
  /**
   * Широта
   */
  latitude: number;
  /**
   * Долгота
   */
  longitude: number;
  /**
   * Время начала работы ПВЗ
   */
  timeFrom: string;
  /**
   * Время окончания работы ПВЗ
   */
  timeTo: string;
}

export interface getOrder_order_delivery_DpDelivery {
  __typename: 'DpDelivery';
  price: number;
  deliveryPoint: getOrder_order_delivery_DpDelivery_deliveryPoint;
}

export interface getOrder_order_delivery_RuPostDelivery {
  __typename: 'RuPostDelivery';
  price: number;
  address: string;
  ruPostBarcode: string;
  ruPostId: number;
}

export type getOrder_order_delivery =
  | getOrder_order_delivery_MetashipDelivery
  | getOrder_order_delivery_CourierDelivery
  | getOrder_order_delivery_DpDelivery
  | getOrder_order_delivery_RuPostDelivery;

export interface getOrder_order_orderItems_image_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface getOrder_order_orderItems_image {
  __typename: 'Photo';
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: getOrder_order_orderItems_image_link;
}

export interface getOrder_order_orderItems_sku_product_category_parentList {
  __typename: 'Category';
  title: string;
}

export interface getOrder_order_orderItems_sku_product_category {
  __typename: 'Category';
  title: string;
  parentList: getOrder_order_orderItems_sku_product_category_parentList[];
}

export interface getOrder_order_orderItems_sku_product {
  __typename: 'Product';
  /**
   * ID продукта
   */
  id: number;
  /**
   * Название продукта
   */
  title: string;
  /**
   * Краткое описание продукта
   */
  shortDescription: string | null;
  /**
   * Информация о категории продукта
   */
  category: getOrder_order_orderItems_sku_product_category | null;
}

export interface getOrder_order_orderItems_sku_characteristicValues_characteristic {
  __typename: 'ProductCharacteristic';
  /**
   * ID характеристики
   */
  id: number;
  /**
   * Заголовок характеристики
   */
  title: string;
}

export interface getOrder_order_orderItems_sku_characteristicValues {
  __typename: 'CharacteristicValue';
  /**
   * Характеристика
   */
  characteristic: getOrder_order_orderItems_sku_characteristicValues_characteristic;
  /**
   * ID значения
   */
  id: number;
  /**
   * Заголовок значения характеристики
   */
  title: string;
  /**
   * Для хар-ки цвет - RGB значение
   */
  value: string;
}

export interface getOrder_order_orderItems_sku {
  __typename: 'Sku';
  /**
   * Цена продажи СКУ
   */
  sellPrice: number;
  /**
   * Стоимость SKU без скидки (зачеркнутая цена)
   */
  fullPrice: number;
  /**
   * Продукт, к которому относится SKU
   */
  product: getOrder_order_orderItems_sku_product;
  /**
   * Значения характеристик SKU
   */
  characteristicValues: getOrder_order_orderItems_sku_characteristicValues[];
}

export interface getOrder_order_orderItems_feedback {
  __typename: 'Feedback';
  /**
   * ID отзыва
   */
  id: number;
  /**
   * Рейтинг
   */
  rating: number;
}

export interface getOrder_order_orderItems {
  __typename: 'OrderItem';
  /**
   * ID позиции заказа
   */
  id: number;
  /**
   * Количество выбранного SKU в заказе
   */
  amount: number;
  /**
   * Фото SKU
   */
  image: getOrder_order_orderItems_image;
  /**
   * Цена покупки для одной штуки (без учета промокодов)
   */
  purchasePrice: number;
  /**
   * Статус по позиции в заказе
   */
  status: OrderItemStatus | null;
  /**
   * SKU позиции в заказе
   */
  sku: getOrder_order_orderItems_sku;
  feedback: getOrder_order_orderItems_feedback | null;
  /**
   * Цена оплаты для одной штуки
   */
  paymentPrice: number;
}

export interface getOrder_order_contacts {
  __typename: 'Contacts';
  /**
   * E-mail
   */
  email: string | null;
  /**
   * Имя
   */
  firstName: string | null;
  /**
   * Фамилия
   */
  lastName: string | null;
  /**
   * Телефон
   */
  phone: string | null;
}

export interface getOrder_order {
  __typename: 'Order';
  status: OrderStatus;
  id: number;
  issueCode: number | null;
  price: number;
  dateCreated: any;
  paymentUrl: string | null;
  comment: string | null;
  delivery: getOrder_order_delivery;
  orderItems: getOrder_order_orderItems[];
  contacts: getOrder_order_contacts;
}

export interface getOrder {
  order: getOrder_order;
}

export interface getOrderVariables {
  orderId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOrders
// ====================================================

export interface getOrders_orders_delivery_MetashipDelivery {
  __typename: 'MetashipDelivery';
  price: number;
}

export interface getOrders_orders_delivery_CourierDelivery_city {
  __typename: 'City';
  /**
   * ID города
   */
  id: number;
}

export interface getOrders_orders_delivery_CourierDelivery {
  __typename: 'CourierDelivery';
  price: number;
  address: string;
  todayDelivery: boolean;
  city: getOrders_orders_delivery_CourierDelivery_city;
}

export interface getOrders_orders_delivery_DpDelivery_deliveryPoint {
  __typename: 'DeliveryPoint';
  /**
   * ID ПВЗ
   */
  id: number;
  /**
   * Адрес ПВЗ
   */
  address: string;
  /**
   * Широта
   */
  latitude: number;
  /**
   * Долгота
   */
  longitude: number;
  /**
   * Время начала работы ПВЗ
   */
  timeFrom: string;
  /**
   * Время окончания работы ПВЗ
   */
  timeTo: string;
}

export interface getOrders_orders_delivery_DpDelivery {
  __typename: 'DpDelivery';
  price: number;
  deliveryPoint: getOrders_orders_delivery_DpDelivery_deliveryPoint;
}

export interface getOrders_orders_delivery_RuPostDelivery {
  __typename: 'RuPostDelivery';
  price: number;
  address: string;
  ruPostBarcode: string;
  ruPostId: number;
}

export type getOrders_orders_delivery =
  | getOrders_orders_delivery_MetashipDelivery
  | getOrders_orders_delivery_CourierDelivery
  | getOrders_orders_delivery_DpDelivery
  | getOrders_orders_delivery_RuPostDelivery;

export interface getOrders_orders_orderItems_image_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface getOrders_orders_orderItems_image {
  __typename: 'Photo';
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: getOrders_orders_orderItems_image_link;
}

export interface getOrders_orders_orderItems_sku_product_category_parentList {
  __typename: 'Category';
  title: string;
}

export interface getOrders_orders_orderItems_sku_product_category {
  __typename: 'Category';
  title: string;
  parentList: getOrders_orders_orderItems_sku_product_category_parentList[];
}

export interface getOrders_orders_orderItems_sku_product {
  __typename: 'Product';
  /**
   * ID продукта
   */
  id: number;
  /**
   * Название продукта
   */
  title: string;
  /**
   * Краткое описание продукта
   */
  shortDescription: string | null;
  /**
   * Информация о категории продукта
   */
  category: getOrders_orders_orderItems_sku_product_category | null;
}

export interface getOrders_orders_orderItems_sku_characteristicValues_characteristic {
  __typename: 'ProductCharacteristic';
  /**
   * ID характеристики
   */
  id: number;
  /**
   * Заголовок характеристики
   */
  title: string;
}

export interface getOrders_orders_orderItems_sku_characteristicValues {
  __typename: 'CharacteristicValue';
  /**
   * Характеристика
   */
  characteristic: getOrders_orders_orderItems_sku_characteristicValues_characteristic;
  /**
   * ID значения
   */
  id: number;
  /**
   * Заголовок значения характеристики
   */
  title: string;
  /**
   * Для хар-ки цвет - RGB значение
   */
  value: string;
}

export interface getOrders_orders_orderItems_sku {
  __typename: 'Sku';
  /**
   * Цена продажи СКУ
   */
  sellPrice: number;
  /**
   * Стоимость SKU без скидки (зачеркнутая цена)
   */
  fullPrice: number;
  /**
   * Продукт, к которому относится SKU
   */
  product: getOrders_orders_orderItems_sku_product;
  /**
   * Значения характеристик SKU
   */
  characteristicValues: getOrders_orders_orderItems_sku_characteristicValues[];
}

export interface getOrders_orders_orderItems_feedback {
  __typename: 'Feedback';
  /**
   * ID отзыва
   */
  id: number;
  /**
   * Рейтинг
   */
  rating: number;
}

export interface getOrders_orders_orderItems {
  __typename: 'OrderItem';
  /**
   * ID позиции заказа
   */
  id: number;
  /**
   * Количество выбранного SKU в заказе
   */
  amount: number;
  /**
   * Фото SKU
   */
  image: getOrders_orders_orderItems_image;
  /**
   * Цена покупки для одной штуки (без учета промокодов)
   */
  purchasePrice: number;
  /**
   * Статус по позиции в заказе
   */
  status: OrderItemStatus | null;
  /**
   * SKU позиции в заказе
   */
  sku: getOrders_orders_orderItems_sku;
  feedback: getOrders_orders_orderItems_feedback | null;
  /**
   * Цена оплаты для одной штуки
   */
  paymentPrice: number;
}

export interface getOrders_orders {
  __typename: 'Order';
  status: OrderStatus;
  id: number;
  issueCode: number | null;
  price: number;
  dateCreated: any;
  paymentUrl: string | null;
  comment: string | null;
  delivery: getOrders_orders_delivery;
  orderItems: getOrders_orders_orderItems[];
}

export interface getOrders {
  orders: getOrders_orders[];
}

export interface getOrdersVariables {
  page?: number | null;
  size?: number | null;
  statuses?: OrderStatus[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: refreshToken
// ====================================================

export interface refreshToken_refreshToken {
  __typename: 'AuthDetails';
  accessToken: string;
  refreshToken: string;
}

export interface refreshToken {
  refreshToken: refreshToken_refreshToken;
}

export interface refreshTokenVariables {
  token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: requestRestorePassword
// ====================================================

export interface requestRestorePassword {
  requestRestorePassword: boolean | null;
}

export interface requestRestorePasswordVariables {
  phone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resendCode
// ====================================================

export interface resendCode {
  resendCode: boolean | null;
}

export interface resendCodeVariables {
  phone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: restorePassword
// ====================================================

export interface restorePassword_restorePassword_customer_contacts {
  __typename: 'Contacts';
  /**
   * Имя
   */
  firstName: string | null;
  /**
   * Телефон
   */
  phone: string | null;
  /**
   * Фамилия
   */
  lastName: string | null;
  /**
   * E-mail
   */
  email: string | null;
  /**
   * Пол
   */
  sex: string | null;
}

export interface restorePassword_restorePassword_customer {
  __typename: 'Customer';
  contacts: restorePassword_restorePassword_customer_contacts | null;
}

export interface restorePassword_restorePassword {
  __typename: 'AuthDetails';
  accessToken: string;
  refreshToken: string;
  customer: restorePassword_restorePassword_customer;
}

export interface restorePassword {
  restorePassword: restorePassword_restorePassword;
}

export interface restorePasswordVariables {
  phone: string;
  password: string;
  code: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveFeedback
// ====================================================

export interface SaveFeedback_saveFeedback {
  __typename: 'Feedback';
  /**
   * ID отзыва
   */
  id: number;
}

export interface SaveFeedback {
  saveFeedback: SaveFeedback_saveFeedback | null;
}

export interface SaveFeedbackVariables {
  feedbackCreate?: FeedbackCreate | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MakeSearchFilters
// ====================================================

export interface MakeSearchFilters_makeSearch_category_parent {
  __typename: 'Category';
  /**
   * Category identifier.
   */
  id: number;
  title: string;
}

export interface MakeSearchFilters_makeSearch_category {
  __typename: 'Category';
  /**
   * Category identifier.
   */
  id: number;
  parent: MakeSearchFilters_makeSearch_category_parent | null;
  title: string;
}

export interface MakeSearchFilters_makeSearch_categoryTree_category_parent {
  __typename: 'Category';
  /**
   * Category identifier.
   */
  id: number;
  title: string;
}

export interface MakeSearchFilters_makeSearch_categoryTree_category_seo {
  __typename: 'SeoCategoryFields';
  header: string | null;
  metaTag: string | null;
}

export interface MakeSearchFilters_makeSearch_categoryTree_category {
  __typename: 'Category';
  /**
   * Category identifier.
   */
  id: number;
  icon: string | null;
  parent: MakeSearchFilters_makeSearch_categoryTree_category_parent | null;
  seo: MakeSearchFilters_makeSearch_categoryTree_category_seo;
  title: string;
  adult: boolean;
}

export interface MakeSearchFilters_makeSearch_categoryTree {
  id: any;
  __typename: 'SearchResultCategoryTreeEntry';
  /**
   * Category.
   */
  category: MakeSearchFilters_makeSearch_categoryTree_category;
  /**
   * Total number of items found in the category.
   */
  total: number;
}

export interface MakeSearchFilters_makeSearch_facets_filter {
  __typename: 'Filter';
  /**
   * Product filter identifier.
   */
  id: number;
  title: string;
  type: FilterType;
  /**
   * Non-null for RANGED_VALUE
   */
  measurementUnit: string | null;
  description: string | null;
}

export interface MakeSearchFilters_makeSearch_facets_buckets_filterValue {
  __typename: 'FilterValue';
  /**
   * Product filter value identifier.
   */
  id: number;
  description: string | null;
  /**
   * Link to an image
   */
  image: string | null;
  name: string;
}

export interface MakeSearchFilters_makeSearch_facets_buckets {
  __typename: 'FacetBucket';
  /**
   * Filter value.
   */
  filterValue: MakeSearchFilters_makeSearch_facets_buckets_filterValue;
  /**
   * Total number of items with the bucket's filter value.
   */
  total: number;
}

export interface MakeSearchFilters_makeSearch_facets_range {
  __typename: 'FacetRange';
  /**
   * Minimal value.
   */
  min: number;
  /**
   * Maximum value.
   */
  max: number;
}

export interface MakeSearchFilters_makeSearch_facets {
  __typename: 'Facet';
  /**
   * Product filter.
   */
  filter: MakeSearchFilters_makeSearch_facets_filter;
  /**
   * Facet buckets.
   */
  buckets: MakeSearchFilters_makeSearch_facets_buckets[] | null;
  /**
   * Facet range.
   */
  range: MakeSearchFilters_makeSearch_facets_range | null;
}

export interface MakeSearchFilters_makeSearch {
  __typename: 'MakeSearchResult';
  /**
   * Search query text.
   */
  queryText: string | null;
  /**
   * Category in which the items were found.
   */
  category: MakeSearchFilters_makeSearch_category | null;
  /**
   * Category tree. Returned only if the pagination has a limit of 0, else empty.
   */
  categoryTree: MakeSearchFilters_makeSearch_categoryTree[];
  /**
   * Product facets. Returned only if the pagination has a limit of 0, else empty.
   */
  facets: MakeSearchFilters_makeSearch_facets[] | null;
  /**
   * Total number of items found.
   */
  total: number;
}

export interface MakeSearchFilters {
  /**
   * Makes search.
   */
  makeSearch: MakeSearchFilters_makeSearch;
}

export interface MakeSearchFiltersVariables {
  queryInput: MakeSearchQueryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MakeSearch
// ====================================================

export interface MakeSearch_makeSearch_items_catalogCard_ProductCard {
  favorite: boolean | null;
  id: number | null;
  __typename: 'ProductCard';
}

export interface MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_offer {
  __typename: 'ProductOffer';
  /**
   * Дата окончания временной скидки
   */
  due: any | null;
  /**
   * Иконка временной скидки
   */
  icon: string | null;
  /**
   * Текст таймера
   */
  text: string | null;
  /**
   * Цвет текста
   */
  textColor: string | null;
}

export interface MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_photos_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
  /**
   * В низком качестве
   */
  low: string;
}

export interface MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_photos_previewLink {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
  /**
   * В низком качестве
   */
  low: string;
}

export interface MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_photos {
  __typename: 'Photo';
  /**
   * Ключ фотографии
   */
  key: string;
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_photos_link;
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  previewLink: MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_photos_previewLink;
}

export interface MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_badges_BottomIconTextBadge {
  __typename: 'BottomIconTextBadge' | 'TopTextBadge';
}

export interface MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_badges_BottomTextBadge {
  __typename: 'BottomTextBadge';
  id: number;
  text: string;
  textColor: string;
  backgroundColor: string;
}

export type MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_badges =
  | MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_badges_BottomIconTextBadge
  | MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_badges_BottomTextBadge;

export interface MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_characteristicValues_characteristic_values {
  __typename: 'CharacteristicValue';
  /**
   * ID значения
   */
  id: number;
  /**
   * Заголовок значения характеристики
   */
  title: string;
  /**
   * Для хар-ки цвет - RGB значение
   */
  value: string;
}

export interface MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_characteristicValues_characteristic {
  __typename: 'ProductCharacteristic';
  /**
   * Массив значений по характеристике
   */
  values: MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_characteristicValues_characteristic_values[];
  /**
   * Заголовок характеристики
   */
  title: string;
  /**
   * ID характеристики
   */
  id: number;
}

export interface MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_characteristicValues {
  __typename: 'CharacteristicValue';
  /**
   * ID значения
   */
  id: number;
  /**
   * Для хар-ки цвет - RGB значение
   */
  value: string;
  /**
   * Заголовок значения характеристики
   */
  title: string;
  /**
   * Характеристика
   */
  characteristic: MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_characteristicValues_characteristic;
}

export interface MakeSearch_makeSearch_items_catalogCard_SkuGroupCard {
  __typename: 'SkuGroupCard';
  /**
   * Является ли товаром 18+
   */
  adult: boolean;
  /**
   * Добавлен ли товар в избранное
   */
  favorite: boolean | null;
  /**
   * Количество отзывов для СКУ группы
   */
  feedbackQuantity: number;
  /**
   * ID СКУ группы (не продукта)
   */
  id: number;
  /**
   * Цена для отображения в каталоге до скидки (минимальная цена до скидки из всех SKU)
   */
  minFullPrice: number;
  /**
   * Цена для отображения в каталоге с учетом скидки (минимальная актуальная цена из всех SKU)
   */
  minSellPrice: number;
  /**
   * Плашка со скидкой с таймером
   */
  offer: MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_offer | null;
  /**
   * Количество заказов для СКУ группы
   */
  ordersQuantity: number;
  /**
   * ID продукта
   */
  productId: number;
  /**
   * Рейтинг товара
   */
  rating: number;
  /**
   * Название товара
   */
  title: string;
  /**
   * Фотографии СКУ группы (приходит только главная пока)
   */
  photos: MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_photos[];
  /**
   * Массив бейджей товара
   */
  badges: MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_badges[];
  /**
   * Значения характеристик для конкретной СКУ группы
   */
  characteristicValues: MakeSearch_makeSearch_items_catalogCard_SkuGroupCard_characteristicValues[];
  skuId: number;
}

export type MakeSearch_makeSearch_items_catalogCard =
  | MakeSearch_makeSearch_items_catalogCard_ProductCard
  | MakeSearch_makeSearch_items_catalogCard_SkuGroupCard;

export interface MakeSearch_makeSearch_items {
  __typename: 'Item';
  /**
   * Found SKU group.
   */
  catalogCard: MakeSearch_makeSearch_items_catalogCard;
}

export interface MakeSearch_makeSearch {
  __typename: 'MakeSearchResult';
  /**
   * Found items.
   */
  items: MakeSearch_makeSearch_items[] | null;
  /**
   * Reports whether search result may have adult content.
   */
  mayHaveAdultContent: boolean;
}

export interface FavoriteList {
  items: SkuGroupCardFragment[] | null;
}

export interface addFavorite {
  addFavoriteSkuGroups: boolean;
}

export interface removeFavorite {
  removeFavoriteSkuGroups: boolean;
}

export interface MakeSearch {
  /**
   * Makes search.
   */
  makeSearch: MakeSearch_makeSearch;
}

export interface Favorite {
  /**
   * Makes search.
   */
  skuGroupFavorites: SkuGroupCardFragment[];
}

export interface MakeSearchVariables {
  queryInput: MakeSearchQueryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setupPassword
// ====================================================

export interface setupPassword_setupPassword_customer_contacts {
  __typename: 'Contacts';
  /**
   * Имя
   */
  firstName: string | null;
  /**
   * Телефон
   */
  phone: string | null;
  /**
   * Фамилия
   */
  lastName: string | null;
  /**
   * E-mail
   */
  email: string | null;
  /**
   * Пол
   */
  sex: string | null;
}

export interface setupPassword_setupPassword_customer {
  __typename: 'Customer';
  contacts: setupPassword_setupPassword_customer_contacts | null;
}

export interface setupPassword_setupPassword {
  __typename: 'AuthDetails';
  accessToken: string;
  refreshToken: string;
  customer: setupPassword_setupPassword_customer;
}

export interface setupPassword {
  setupPassword: setupPassword_setupPassword;
}

export interface setupPasswordVariables {
  phone: string;
  password: string;
  code: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: signIn
// ====================================================

export interface signIn_signIn_customer_contacts {
  __typename: 'Contacts';
  /**
   * Имя
   */
  firstName: string | null;
  /**
   * Телефон
   */
  phone: string | null;
  /**
   * Фамилия
   */
  lastName: string | null;
  /**
   * E-mail
   */
  email: string | null;
}

export interface signIn_signIn_customer {
  __typename: 'Customer';
  /**
   * ID пользователя
   */
  id: number;
  contacts: signIn_signIn_customer_contacts | null;
}

export interface signIn_signIn {
  __typename: 'AuthDetails';
  accessToken: string;
  refreshToken: string;
  customer: signIn_signIn_customer;
}

export interface signIn {
  /**
   * changePassword(oldPassword: String!, newPassword: String!): Customer!
   */
  signIn: signIn_signIn;
}

export interface signInVariables {
  username: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: signUp
// ====================================================

export interface signUp_signUp_customer_contacts {
  __typename: 'Contacts';
  /**
   * Имя
   */
  firstName: string | null;
}

export interface signUp_signUp_customer {
  __typename: 'Customer';
  /**
   * ID пользователя
   */
  id: number;
  contacts: signUp_signUp_customer_contacts | null;
}

export interface signUp_signUp {
  __typename: 'AuthDetails';
  accessToken: string;
  refreshToken: string;
  customer: signUp_signUp_customer;
}

export interface signUp {
  signUp: signUp_signUp;
}

export interface signUpVariables {
  phone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateUser
// ====================================================

export interface updateUser_updateUser {
  __typename: 'Contacts';
  /**
   * Имя
   */
  firstName: string | null;
  /**
   * Телефон
   */
  phone: string | null;
  /**
   * Фамилия
   */
  lastName: string | null;
  /**
   * E-mail
   */
  email: string | null;
}

export interface updateUser {
  updateUser: updateUser_updateUser | null;
}

export interface updateUserVariables {
  contacts: ContactsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: verifyPhone
// ====================================================

export interface verifyPhone_verifyPhone {
  __typename: 'Customer';
  phoneVerified: boolean;
  /**
   * ID пользователя
   */
  id: number;
}

export interface verifyPhone {
  verifyPhone: verifyPhone_verifyPhone;
}

export interface verifyPhoneVariables {
  code: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: verifyRestoreCode
// ====================================================

export interface verifyRestoreCode {
  verifyRestoreCode: boolean | null;
}

export interface verifyRestoreCodeVariables {
  code: number;
  phone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: cartData
// ====================================================

export interface cartData_sku_photo_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface cartData_sku_photo {
  __typename: 'Photo';
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: cartData_sku_photo_link;
}

export interface cartData_sku_characteristicValues_characteristic {
  __typename: 'ProductCharacteristic';
  /**
   * Заголовок характеристики
   */
  title: string;
}

export interface cartData_sku_characteristicValues {
  __typename: 'CharacteristicValue';
  /**
   * Заголовок значения характеристики
   */
  title: string;
  /**
   * Для хар-ки цвет - RGB значение
   */
  value: string;
  /**
   * Характеристика
   */
  characteristic: cartData_sku_characteristicValues_characteristic;
}

export interface cartData_sku_product {
  __typename: 'Product';
  /**
   * ID продукта
   */
  id: number;
  /**
   * Название продукта
   */
  title: string;
  /**
   * Краткое описание продукта
   */
  shortDescription: string | null;
}

export interface cartData_sku {
  __typename: 'Sku';
  /**
   * Доступное количество по SKU
   */
  availableAmount: number;
  /**
   * ID SKU
   */
  id: number;
  /**
   * Стоимость SKU без скидки (зачеркнутая цена)
   */
  fullPrice: number;
  /**
   * Цена продажи СКУ
   */
  sellPrice: number;
  /**
   * SKU title, не используется на клиентах
   */
  skuTitle: string;
  /**
   * Основная фотография для СКУ
   */
  photo: cartData_sku_photo;
  /**
   * Значения характеристик SKU
   */
  characteristicValues: cartData_sku_characteristicValues[];
  /**
   * Продукт, к которому относится SKU
   */
  product: cartData_sku_product;
  /**
   * Вес SKU
   */
  weight: number | null;
}

export interface cartData {
  __typename: 'CartItem';
  amount: number;
  sku: cartData_sku;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DefaultCardFragment
// ====================================================

export interface DefaultCardFragment_offer {
  __typename: 'ProductOffer';
  /**
   * Дата окончания временной скидки
   */
  due: any | null;
  /**
   * Иконка временной скидки
   */
  icon: string | null;
  /**
   * Текст таймера
   */
  text: string | null;
  /**
   * Цвет текста
   */
  textColor: string | null;
}

export interface DefaultCardFragment {
  __typename: 'ProductCard' | 'SkuGroupCard';
  /**
   * Является ли 18+
   */
  adult: boolean;
  /**
   * Добавлено ли в избранное
   */
  favorite: boolean;
  /**
   * Количество отзывов
   */
  feedbackQuantity: number;
  /**
   * Catalog card entity identifier.
   */
  id: number;
  /**
   * Цена для отображения в карточке товара до скидки (минимальная цена до скидки из всех SKU)
   */
  minFullPrice: number;
  /**
   * Цена для отображения в карточке товара с учетом скидки (минимальная актуальная цена из всех SKU)
   */
  minSellPrice: number;
  /**
   * Информация о временной скидке
   */
  offer: DefaultCardFragment_offer | null;
  /**
   * Количество заказов
   */
  ordersQuantity: number;
  /**
   * ID продукта
   */
  productId: number;
  /**
   * Рейтинг
   */
  rating: number;
  /**
   * Заголовок
   */
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SkuGroupCardFragment
// ====================================================

export interface SkuGroupCardFragment_offer {
  __typename: 'ProductOffer';
  /**
   * Дата окончания временной скидки
   */
  due: any | null;
  /**
   * Иконка временной скидки
   */
  icon: string | null;
  /**
   * Текст таймера
   */
  text: string | null;
  /**
   * Цвет текста
   */
  textColor: string | null;
}

export interface SkuGroupCardFragment_photos_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
  /**
   * В низком качестве
   */
  low: string;
}

export interface SkuGroupCardFragment_photos_previewLink {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
  /**
   * В низком качестве
   */
  low: string;
}

export interface SkuGroupCardFragment_photos {
  __typename: 'Photo';
  /**
   * Ключ фотографии
   */
  key: string;
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: SkuGroupCardFragment_photos_link;
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  previewLink: SkuGroupCardFragment_photos_previewLink;
}

export interface SkuGroupCardFragment_badges_BottomIconTextBadge {
  __typename: 'BottomIconTextBadge' | 'TopTextBadge';
}

export interface SkuGroupCardFragment_badges_BottomTextBadge {
  __typename: 'BottomTextBadge';
  id: number;
  text: string;
  textColor: string;
  backgroundColor: string;
}

export type SkuGroupCardFragment_badges =
  | SkuGroupCardFragment_badges_BottomIconTextBadge
  | SkuGroupCardFragment_badges_BottomTextBadge;

export interface SkuGroupCardFragment_characteristicValues_characteristic_values {
  __typename: 'CharacteristicValue';
  /**
   * ID значения
   */
  id: number;
  /**
   * Заголовок значения характеристики
   */
  title: string;
  /**
   * Для хар-ки цвет - RGB значение
   */
  value: string;
}

export interface SkuGroupCardFragment_characteristicValues_characteristic {
  __typename: 'ProductCharacteristic';
  /**
   * Массив значений по характеристике
   */
  values: SkuGroupCardFragment_characteristicValues_characteristic_values[];
  /**
   * Заголовок характеристики
   */
  title: string;
  /**
   * ID характеристики
   */
  id: number;
}

export interface SkuGroupCardFragment_characteristicValues {
  __typename: 'CharacteristicValue';
  /**
   * ID значения
   */
  id: number;
  /**
   * Для хар-ки цвет - RGB значение
   */
  value: string;
  /**
   * Заголовок значения характеристики
   */
  title: string;
  /**
   * Характеристика
   */
  characteristic: SkuGroupCardFragment_characteristicValues_characteristic;
}

export interface SkuGroupCardFragment {
  __typename: 'SkuGroupCard';
  /**
   * Является ли товаром 18+
   */
  adult: boolean;
  /**
   * Добавлен ли товар в избранное
   */
  favorite: boolean;
  /**
   * Количество отзывов для СКУ группы
   */
  feedbackQuantity: number;
  /**
   * ID СКУ группы (не продукта)
   */
  id: number;
  /**
   * Цена для отображения в каталоге до скидки (минимальная цена до скидки из всех SKU)
   */
  minFullPrice: number;
  /**
   * Цена для отображения в каталоге с учетом скидки (минимальная актуальная цена из всех SKU)
   */
  minSellPrice: number;
  /**
   * Плашка со скидкой с таймером
   */
  offer: SkuGroupCardFragment_offer | null;
  /**
   * Количество заказов для СКУ группы
   */
  ordersQuantity: number;
  /**
   * ID продукта
   */
  productId: number;
  /**
   * Рейтинг товара
   */
  rating: number;
  /**
   * Название товара
   */
  title: string;
  /**
   * Фотографии СКУ группы (приходит только главная пока)
   */
  photos: SkuGroupCardFragment_photos[];
  /**
   * Массив бейджей товара
   */
  badges: SkuGroupCardFragment_badges[];
  /**
   * Значения характеристик для конкретной СКУ группы
   */
  characteristicValues: SkuGroupCardFragment_characteristicValues[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CategoryFragment
// ====================================================

export interface CategoryFragment_parent {
  __typename: 'Category';
  /**
   * Category identifier.
   */
  id: number;
  title: string;
}

export interface CategoryFragment_seo {
  __typename: 'SeoCategoryFields';
  header: string | null;
  metaTag: string | null;
}

export interface CategoryFragment {
  __typename: 'Category';
  /**
   * Category identifier.
   */
  id: number;
  icon: string | null;
  parent: CategoryFragment_parent | null;
  seo: CategoryFragment_seo;
  title: string;
  adult: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CategoryShortFragment
// ====================================================

export interface CategoryShortFragment_parent {
  __typename: 'Category';
  /**
   * Category identifier.
   */
  id: number;
  title: string;
}

export interface CategoryShortFragment {
  __typename: 'Category';
  /**
   * Category identifier.
   */
  id: number;
  parent: CategoryShortFragment_parent | null;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FacetFragment
// ====================================================

export interface FacetFragment_filter {
  __typename: 'Filter';
  /**
   * Product filter identifier.
   */
  id: number;
  title: string;
  type: FilterType;
  /**
   * Non-null for RANGED_VALUE
   */
  measurementUnit: string | null;
  description: string | null;
}

export interface FacetFragment_buckets_filterValue {
  __typename: 'FilterValue';
  /**
   * Product filter value identifier.
   */
  id: number;
  description: string | null;
  /**
   * Link to an image
   */
  image: string | null;
  name: string;
}

export interface FacetFragment_buckets {
  __typename: 'FacetBucket';
  /**
   * Filter value.
   */
  filterValue: FacetFragment_buckets_filterValue;
  /**
   * Total number of items with the bucket's filter value.
   */
  total: number;
}

export interface FacetFragment_range {
  __typename: 'FacetRange';
  /**
   * Minimal value.
   */
  min: number;
  /**
   * Maximum value.
   */
  max: number;
}

export interface FacetFragment {
  __typename: 'Facet';
  /**
   * Product filter.
   */
  filter: FacetFragment_filter;
  /**
   * Facet buckets.
   */
  buckets: FacetFragment_buckets[] | null;
  /**
   * Facet range.
   */
  range: FacetFragment_range | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ordersData
// ====================================================

export interface ordersData_delivery_MetashipDelivery {
  __typename: 'MetashipDelivery';
  price: number;
}

export interface ordersData_delivery_CourierDelivery_city {
  __typename: 'City';
  /**
   * ID города
   */
  id: number;
}

export interface ordersData_delivery_CourierDelivery {
  __typename: 'CourierDelivery';
  price: number;
  address: string;
  todayDelivery: boolean;
  city: ordersData_delivery_CourierDelivery_city;
}

export interface ordersData_delivery_DpDelivery_deliveryPoint {
  __typename: 'DeliveryPoint';
  /**
   * ID ПВЗ
   */
  id: number;
  /**
   * Адрес ПВЗ
   */
  address: string;
  /**
   * Широта
   */
  latitude: number;
  /**
   * Долгота
   */
  longitude: number;
  /**
   * Время начала работы ПВЗ
   */
  timeFrom: string;
  /**
   * Время окончания работы ПВЗ
   */
  timeTo: string;
}

export interface ordersData_delivery_DpDelivery {
  __typename: 'DpDelivery';
  price: number;
  deliveryPoint: ordersData_delivery_DpDelivery_deliveryPoint;
}

export interface ordersData_delivery_RuPostDelivery {
  __typename: 'RuPostDelivery';
  price: number;
  address: string;
  ruPostBarcode: string;
  ruPostId: number;
}

export type ordersData_delivery =
  | ordersData_delivery_MetashipDelivery
  | ordersData_delivery_CourierDelivery
  | ordersData_delivery_DpDelivery
  | ordersData_delivery_RuPostDelivery;

export interface ordersData_orderItems_image_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface ordersData_orderItems_image {
  __typename: 'Photo';
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: ordersData_orderItems_image_link;
}

export interface ordersData_orderItems_sku_product_category_parentList {
  __typename: 'Category';
  title: string;
}

export interface ordersData_orderItems_sku_product_category {
  __typename: 'Category';
  title: string;
  parentList: ordersData_orderItems_sku_product_category_parentList[];
}

export interface ordersData_orderItems_sku_product {
  __typename: 'Product';
  /**
   * ID продукта
   */
  id: number;
  /**
   * Название продукта
   */
  title: string;
  /**
   * Краткое описание продукта
   */
  shortDescription: string | null;
  /**
   * Информация о категории продукта
   */
  category: ordersData_orderItems_sku_product_category | null;
}

export interface ordersData_orderItems_sku_characteristicValues_characteristic {
  __typename: 'ProductCharacteristic';
  /**
   * ID характеристики
   */
  id: number;
  /**
   * Заголовок характеристики
   */
  title: string;
}

export interface ordersData_orderItems_sku_characteristicValues {
  __typename: 'CharacteristicValue';
  /**
   * Характеристика
   */
  characteristic: ordersData_orderItems_sku_characteristicValues_characteristic;
  /**
   * ID значения
   */
  id: number;
  /**
   * Заголовок значения характеристики
   */
  title: string;
  /**
   * Для хар-ки цвет - RGB значение
   */
  value: string;
}

export interface ordersData_orderItems_sku {
  __typename: 'Sku';
  /**
   * Цена продажи СКУ
   */
  sellPrice: number;
  /**
   * Стоимость SKU без скидки (зачеркнутая цена)
   */
  fullPrice: number;
  /**
   * Продукт, к которому относится SKU
   */
  product: ordersData_orderItems_sku_product;
  /**
   * Значения характеристик SKU
   */
  characteristicValues: ordersData_orderItems_sku_characteristicValues[];
}

export interface ordersData_orderItems_feedback {
  __typename: 'Feedback';
  /**
   * ID отзыва
   */
  id: number;
  /**
   * Рейтинг
   */
  rating: number;
}

export interface ordersData_orderItems {
  __typename: 'OrderItem';
  /**
   * ID позиции заказа
   */
  id: number;
  /**
   * Количество выбранного SKU в заказе
   */
  amount: number;
  /**
   * Фото SKU
   */
  image: ordersData_orderItems_image;
  /**
   * Цена покупки для одной штуки (без учета промокодов)
   */
  purchasePrice: number;
  /**
   * Статус по позиции в заказе
   */
  status: OrderItemStatus | null;
  /**
   * SKU позиции в заказе
   */
  sku: ordersData_orderItems_sku;
  feedback: ordersData_orderItems_feedback | null;
  /**
   * Цена оплаты для одной штуки
   */
  paymentPrice: number;
}

export interface ordersData {
  __typename: 'Order';
  status: OrderStatus;
  id: number;
  issueCode: number | null;
  price: number;
  dateCreated: any;
  paymentUrl: string | null;
  comment: string | null;
  delivery: ordersData_delivery;
  orderItems: ordersData_orderItems[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductBadgesFragment
// ====================================================

export interface ProductBadgesFragment_BottomIconTextBadge {
  __typename: 'BottomIconTextBadge' | 'TopTextBadge';
}

export interface ProductBadgesFragment_BottomTextBadge {
  __typename: 'BottomTextBadge';
  id: number;
  text: string;
  textColor: string;
  backgroundColor: string;
}

export type ProductBadgesFragment = ProductBadgesFragment_BottomIconTextBadge | ProductBadgesFragment_BottomTextBadge;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductCharacteristicsFragment
// ====================================================

export interface ProductCharacteristicsFragment_values_photo_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface ProductCharacteristicsFragment_values_photo_original {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface ProductCharacteristicsFragment_values_photo {
  __typename: 'Photo';
  /**
   * Ключ фотографии
   */
  key: string;
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: ProductCharacteristicsFragment_values_photo_link;
  /**
   * Ссылка на оригинал фотографии
   */
  original: ProductCharacteristicsFragment_values_photo_original;
}

export interface ProductCharacteristicsFragment_values {
  __typename: 'CharacteristicValue';
  /**
   * ID значения
   */
  id: number;
  /**
   * Заголовок значения характеристики
   */
  title: string;
  /**
   * Для хар-ки цвет - RGB значение
   */
  value: string;
  /**
   * Фото значения характеристики
   */
  photo: ProductCharacteristicsFragment_values_photo | null;
}

export interface ProductCharacteristicsFragment {
  __typename: 'ProductCharacteristic';
  /**
   * ID характеристики
   */
  id: number;
  /**
   * Заголовок характеристики
   */
  title: string;
  /**
   * Массив значений по характеристике
   */
  values: ProductCharacteristicsFragment_values[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: skuFields
// ====================================================

export interface skuFields_product {
  __typename: 'Product';
  /**
   * ID продукта
   */
  id: number;
  /**
   * Название продукта
   */
  title: string;
  /**
   * Краткое описание продукта
   */
  shortDescription: string | null;
}

export interface skuFields_characteristicValues_characteristic {
  __typename: 'ProductCharacteristic';
  /**
   * ID характеристики
   */
  id: number;
  /**
   * Заголовок характеристики
   */
  title: string;
}

export interface skuFields_characteristicValues {
  __typename: 'CharacteristicValue';
  /**
   * Характеристика
   */
  characteristic: skuFields_characteristicValues_characteristic;
  /**
   * Заголовок значения характеристики
   */
  title: string;
  /**
   * Для хар-ки цвет - RGB значение
   */
  value: string;
}

export interface skuFields_photos_link {
  __typename: 'PhotoLink';
  /**
   * В высоком качестве
   */
  high: string;
}

export interface skuFields_photos {
  __typename: 'Photo';
  /**
   * Объект ссылок (HQ + LQ) на вариацию фото по переданному параметру размера
   */
  link: skuFields_photos_link;
}

export interface skuFields {
  __typename: 'Sku';
  /**
   * ID SKU
   */
  id: number;
  /**
   * Продукт, к которому относится SKU
   */
  product: skuFields_product;
  /**
   * Доступное количество по SKU
   */
  availableAmount: number;
  /**
   * Значения характеристик SKU
   */
  characteristicValues: skuFields_characteristicValues[];
  /**
   * Цена продажи СКУ
   */
  sellPrice: number;
  /**
   * Стоимость SKU без скидки (зачеркнутая цена)
   */
  fullPrice: number;
  /**
   * Массив фотографий для СКУ
   */
  photos: skuFields_photos[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DeliveryType {
  COURIER = 'COURIER',
  DELIVERY_POINT = 'DELIVERY_POINT',
  METASHIP = 'METASHIP',
  POSTAMAT = 'POSTAMAT',
  RUSSIAN_POST = 'RUSSIAN_POST',
}

/**
 * Тип запрашиваемой главной страницы
 */
export enum DisplayType {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
}

export enum FeedbackFilterType {
  PHOTOS = 'PHOTOS',
}

export enum FeedbackSortType {
  DATE_DESC = 'DATE_DESC',
  RATING_ASC = 'RATING_ASC',
  RATING_DESC = 'RATING_DESC',
  RELEVANCE = 'RELEVANCE',
}

/**
 * Product filter type.
 */
export enum FilterInputType {
  LIST = 'LIST',
  RANGE = 'RANGE',
}

export enum FilterType {
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  MULTIPLE_CHOICE_TAG = 'MULTIPLE_CHOICE_TAG',
  RANGED_ENUM = 'RANGED_ENUM',
  RANGED_VALUE = 'RANGED_VALUE',
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  SINGLE_CHOICE_TAG = 'SINGLE_CHOICE_TAG',
}

/**
 * Статус по позиции в заказе
 */
export enum OrderItemStatus {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  NOT_CREATED = 'NOT_CREATED',
  PAID = 'PAID',
  PROCESSED = 'PROCESSED',
  RECEIVED = 'RECEIVED',
  RETURNED = 'RETURNED',
}

/**
 * Статус заказа
 */
export enum OrderStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  DELIVERED = 'DELIVERED',
  IN_DELIVERY = 'IN_DELIVERY',
  NOTIFICATION = 'NOTIFICATION',
  NOT_CREATED = 'NOT_CREATED',
  PROCESSED = 'PROCESSED',
  RECEIVED = 'RECEIVED',
  RETURNED = 'RETURNED',
}

/**
 * Adult content showing flag.
 */
export enum ShowAdultContent {
  FALSE = 'FALSE',
  NONE = 'NONE',
  TRUE = 'TRUE',
}

/**
 * Search query result sorting.
 */
export enum Sort {
  BY_DATE_ADDED_ASC = 'BY_DATE_ADDED_ASC',
  BY_DATE_ADDED_DESC = 'BY_DATE_ADDED_DESC',
  BY_ORDERS_NUMBER_ASC = 'BY_ORDERS_NUMBER_ASC',
  BY_ORDERS_NUMBER_DESC = 'BY_ORDERS_NUMBER_DESC',
  BY_PRICE_ASC = 'BY_PRICE_ASC',
  BY_PRICE_DESC = 'BY_PRICE_DESC',
  BY_RATING_ASC = 'BY_RATING_ASC',
  BY_RATING_DESC = 'BY_RATING_DESC',
  BY_RELEVANCE_DESC = 'BY_RELEVANCE_DESC',
}

export enum SortBy {
  date = 'date',
  default = 'default',
  orders = 'orders',
  price = 'price',
  rating = 'rating',
}

export enum SortOrder {
  ascending = 'ascending',
  descending = 'descending',
}

export enum FavoriteSort {
  PRICE_ASCENDING = 'PRICE_ASCENDING',
  PRICE_DESCENDING = 'PRICE_DESCENDING',
  DATE_ADDED_DESCENDING = 'DATE_ADDED_DESCENDING',
  DISCOUNT_ASCENDING = 'DISCOUNT_ASCENDING',
}

export const FavoriteSortValue = {
  PRICE_ASCENDING: {
    sortBy: 'PRICE',
    order: 'ASCENDING',
  },
  PRICE_DESCENDING: {
    sortBy: 'PRICE',
    order: 'DESCENDING',
  },
  DATE_ADDED_DESCENDING: {
    sortBy: 'DATE_ADDED',
    order: 'DESCENDING',
  },
  DISCOUNT_ASCENDING: {
    sortBy: 'DISCOUNT',
    order: 'ASCENDING',
  },
};

export interface CartItemInput {
  amount: number;
  skuId: number;
}

export interface ContactsInput {
  birthDate?: any | null;
  email: string;
  firstName: string;
  isMailAccept?: boolean | null;
  lastName: string;
  patronymic?: string | null;
  phone: string;
  sex?: string | null;
}

export interface CreateOrderInput {
  comment?: string | null;
  contacts: ContactsInput;
  delivery: DeliveryInput;
  orderItems: OrderItemInput[];
  promoCode?: string | null;
}

export interface DeliveryInput {
  address?: string | null;
  city?: string | null;
  cityId?: number | null;
  date?: any | null;
  deliveryOptionId?: number | null;
  deliveryPointId?: number | null;
  deliveryPointKey?: string | null;
  isTodayDelivery?: boolean | null;
  period?: string | null;
  price: number;
  serviceCode?: string | null;
  serviceTariffId?: string | null;
  type: DeliveryType;
}

export interface ExpectedDeliveryInput {
  delivery: DeliveryInput;
  orderItems: OrderItemInput[];
  promoCode?: string | null;
}

export interface FeedbackCreate {
  anonymous: boolean;
  cons: string;
  content: string;
  orderItemId: number;
  photos?: string[] | null;
  pros: string;
  rating: number;
}

/**
 * Product filter input.
 */
export interface FilterInput {
  filterType: FilterInputType;
  id: string;
  range?: FilterRangeInput | null;
  list?: FilterListInput | null;
}

/**
 * List filter input values.
 */
export interface FilterListInput {
  values: string[];
}

/**
 * Range filter input values. At least one of the boundaries must be not null.
 */
export interface FilterRangeInput {
  min?: number | null;
  max?: number | null;
}

/**
 * Search suggestions query input.
 */
export interface GetSuggestionsInput {
  text: string;
  popularSuggestionsLimit?: number | null;
  textSuggestionsLimit?: number | null;
  catalogCardSuggestionsLimit?: number | null;
  categorySuggestionsLimit?: number | null;
  shopSuggestionsLimit?: number | null;
}

/**
 * Search query input.
 */
export interface MakeSearchQueryInput {
  text?: string | null;
  categoryId?: string | null;
  offerCategoryId?: string | null;
  shopId?: string | null;
  showAdultContent: ShowAdultContent;
  filters: FilterInput[];
  sort: Sort;
  pagination: PaginationInput;
  fastCategoriesLevelOffset?: number | null;
  fastCategoriesLimit?: number | null;
  getFastCategories?: boolean | null;
  correctQuery?: boolean | null;
  token?: string | null;
  getPromotionItems?: boolean | null;
  link?: string | null;
}

/**
 * Favorite query input.
 */
export interface FavoriteQueryInput {
  sort: FavoriteSort;
  pagination: PaginationInput;
}

/**
 * Favorite filter.
 */
export interface FavoritesFilter {
  page?: number | null;
  size?: number | null;
  order: string | null;
  sortBy: string | null;
}

export interface OrderItemInput {
  amount: number;
  purchasePrice: number;
  skuId: number;
}

/**
 * Pagination input parameters.
 */
export interface PaginationInput {
  offset: number;
  limit: number;
}

export interface PromoCodeInput {
  delivery: DeliveryInput;
  orderItems?: OrderItemInput[] | null;
  promoCode: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
