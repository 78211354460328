export const viewAuthorization = function () {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'personal_account_view_authorization',
    eventCategory: 'personal_account',
    eventAction: 'view_authorization',
  };
};

export const registrationRequestSms = function (number) {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'personal_account_registration_request_sms_success',
    eventCategory: 'personal_account',
    eventAction: 'registration',
    eventLabel: 'request_sms_success',
    properties: {
      phone_part: number,
    },
  };
};

export const registrationRequestSmsFailed = function (number) {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'personal_account_registration_request_sms_failed',
    eventCategory: 'personal_account',
    eventAction: 'registration',
    eventLabel: 'request_sms_failed',
    properties: {
      phone_part: number,
    },
  };
};

export const registrationPasswordSubmit = function (number) {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'personal_account_registration_password_submit',
    eventCategory: 'personal_account',
    eventAction: 'registration',
    eventLabel: 'password_submit',
    properties: {
      phone_part: number,
    },
  };
};
