<template lang="pug">
.ds-search-page
  search-ds(
    has-category-filter
    :additional-query-input='additionalQueryInput'
    search-type="search"
  )
    .ui-wrapper(slot='header' slot-scope='{ total, queryText }')
      h1.search-title {{ getTitle(queryText, total) }}
</template>

<script>
import { SeoDataMixin } from '~/mixins/seoData';
import { StaticDataMixin } from '~/mixins/staticData';

import ending from '~/modules/common/ending';

import SearchDs from '~/modules/search/components/index.vue';
import { isEqualArray } from '~/modules/common/equal-array';

export default {
  components: {
    SearchDs,
  },

  mixins: [SeoDataMixin, StaticDataMixin],

  head() {
    return this.seoData[this.seoDataPages.SEARCH]({}, this.$route.path);
  },

  computed: {
    additionalQueryInput() {
      return {
        text: this.$route.query.query,
      };
    },
    query() {
      return this.$route.query.query || '';
    },
    dshopFilterCategories() {
      const result = this.$route.query.cat || [];
      return Array.isArray(result) ? result : [result];
    },
    sort() {
      return +this.$route.query.sort || 0;
    },
    price() {
      return this.$route.query.price || [undefined, undefined];
    },
  },

  beforeDestroy() {
    this.$eventManager.off('updateQuery', this.onUpdateQuery);
  },

  mounted() {
    this.$eventManager.on('updateQuery', this.onUpdateQuery);
  },

  methods: {
    getTitle(queryText, total) {
      const title = `${total} ${ending(total, ['результат', 'результата', 'результатов'])}`;
      if (queryText) {
        return `${title} по запросу «${queryText}»`;
      }
      return title;
    },
    onUpdateQuery(payload) {
      const name = payload.name === 'dshopFilterCategories' ? 'cat' : payload.name;
      const value = payload.value;
      if (isEqualArray(this.$route.query[name], value)) {
        // does not update same value
        return;
      }
      const query = { ...this.$route.query };
      if (value === undefined) {
        delete query[name];
      } else {
        query[name] = value;
      }
      this.$router.push({
        query,
        params: { toPosition: { y: Math.min(window.scrollY, this.$refs.searchResult.$refs.content.offsetTop) } },
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.ds-search-page
  .search-title
    Text(TitleL)
    margin-bottom 32px
    margin-top 32px

    +Media(Mobile, Tablet)
      margin-top 12px
      margin-bottom 14px
</style>
