<template lang="pug">
.ds-offers
  .ui-wrapper(v-if="offers.length")
    offer-ds(
      v-for="offer in offers"
      :key="offer.id"
      :id="offer.id"
      :title="offer.title"
      :products="offer.productsPage.content"
    )
    //- infinite-loading(
    //-   v-if="offers.length"
    //-   spinner='spiral'
    //-   @infinite='setMoreOffers'
    //- )
    //-   div(slot='no-more')
    //-   div(slot='spinner')
    //-     spinner-ui.search-spinner
</template>

<script>
import OfferDs from '~/components/offer';
import MainBlocksProfitDs from '~/components/main-blocks-profit';
// import { getMainOffers } from '~/apollo-api/getMainOffers';
import InfiniteLoading from 'vue-infinite-loading';
import SpinnerUi from '~/components/spinner.vue';
export default {
  components: {
    OfferDs,
    MainBlocksProfitDs,
    SpinnerUi,
    InfiniteLoading,
  },

  props: {
    offers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      preloadOffers: [],
      allOffers: [],
      page: 0,
    };
  },
  // async fetch() {
  //   const preload = (await getMainOffers()).offers;
  //   const filteredOffers = preload.filter((offer) => {
  //     return offer.productsPage && offer.productsPage.content.length > 0;
  //   });
  //   const third = Math.ceil(filteredOffers.length / 3);
  //   this.preloadOffers = filteredOffers.slice(0, third);
  // },
  // mounted() {
  //   if (this.separatedOffers) {
  //     this.allOffers = this.separatedOffers[0];
  //   }
  // },
  // methods: {
  //   setMoreOffers($state) {
  //     setTimeout(() => {
  //       if (this.page === 0) {
  //         this.allOffers = [...this.offers[this.page]];
  //         this.page++;
  //         $state.loaded();
  //       } else if (this.page < 3) {
  //         this.allOffers = [...this.allOffers, ...this.offers[this.page]];
  //         this.page++;
  //         $state.loaded();
  //       } else {
  //         $state.complete();
  //       }
  //     }, 100);
  //   },
  // },
};
</script>

<style lang="stylus">
.ds-offers
  .ds-main-blocks-profit
  .ds-offer
    margin-bottom 56px
    +Media(Mobile, Tablet)
      margin-bottom 40px
  .search-spinner
    margin 56px auto
</style>
